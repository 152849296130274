import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { passwordReg, textReg } from "../../utils/regex";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import Spinner from "../generic/Spinner";
import { useState } from "react";
import {
  onDeleteGovStaff,
  setGovconfig,
  setGovStaffconfig,
} from "../../services/user";
import { CiCircleInfo } from "react-icons/ci";

export default (props) => {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      var res = await onDeleteGovStaff({ email: props.item.email });

      toast.success("Staff deleted!");
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
      props.show(false);
      props.refresh();
    }
  };

  return (
    <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#000000a9] justify-center items-center ">
      <Spinner loading={loading} />

      <div className="bg-white flex flex-col rounded h-[50vh] w-[30vw] justify-center   p-10 ">
        <CiCircleInfo size={40} className="text-primary mb-4" />
        <h2 className="text-gray-600 font-semibold capitalize">
          Confirm to delete Staff
        </h2>
        {props.lockstatus}{" "}
        <span className="text-blue-400 text-sm font-light">
          {props.item.firstname} {props.item.lastname}
        </span>
        <p className="text-primary text-sm">
          This staff be deleted completely from the database
        </p>
        <div className="w-full  flex  gap-3 justify-center items-end">
          <Button
            outlined={true}
            width={55}
            text="Cancel"
            onBtnClick={() => {
              props.show(false);
            }}
          />

          <Button width={55} text={`Delete`} onBtnClick={submit} />
        </div>
      </div>
    </div>
  );
};
