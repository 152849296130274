import { useForm } from "react-hook-form";
import { LGAs, States } from "../../constants/data";
import { useLocationHook } from "../../hooks/useLocationHook";
import { usePickerhook } from "../../hooks/usePickerhook";
import { addressRegex, textReg } from "../../utils/regex";
import Button from "../generic/Button";
import { ListPicker } from "../ui/ListPicker";
import MapComponent from "../ui/MapItem";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const SetMapLocation
 = (props) => {

  var userschool = useSelector((state) => state.userschool);


 useEffect(()=>{



  console.log(userschool,".....,,,,,")

 },[])
  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-[#000000a7] w-[100vw] h-[100vh]">
      <div className="bg-white w-[50vw] rounded px-10 py-4">
        { <MapComponent
        userschool={userschool}
          isviewonly={false}
          cancel={props.cancel}
          Hook={props.Hook}
        /> }
  


        
      </div>
    </div>
  );
};

export { SetMapLocation };
