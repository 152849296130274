import { toast } from "react-toastify"
import { usePhotoUpload } from "../../hooks/usePhotoUploadhook"
import Button from "../generic/Button"
import { PhotoUpload } from "../generic/PhotoUpload"
import { onUploadCover } from "../../services/school"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const ChangeCoverPhoto=(props)=>{
    var choosephotohook = usePhotoUpload()
    var navigate=useNavigate()
const userschool =useSelector(state=>state.userschool)

const submit=async()=>{
if(!choosephotohook.file){


    toast.error("Photo cannot be empty")
    return
}
const formdata = new FormData()
formdata.append(  'schoolname', userschool.schoolname)
formdata.append(  'schoollogo', choosephotohook.file)


props.loading(true)
props.showaddmodal(false)

var res =await onUploadCover(formdata)
console.log(res)

props.showaddmodal(true)
props.loading(false)

if(res.message){

    toast(res.message)
}
props.showaddmodal(false)
navigate('/dashboard')
}

    return(<div className="w-[100vw] h-[100vh] flex justify-center items-center zindex fixed top-0 left-0 bg-[#00000092] "> 
  <div className='bg-white flex flex-col items-center justify-center rounded  min-w-[30vw] md:w-[30vw] p-10 '>

<h2 className='text-primary'>Set school cover photo</h2>
 <p className="text-black font-thin text-xs"> Must be less than 2MB  </p>

       <div className='w-full flex justify-center items-center'>
       <PhotoUpload hook={choosephotohook} />
       </div>
      <div className='w-full  flex  gap-3 justify-end'> 

     
       <Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.showaddmodal(false)}} />
       <Button width={55}  text="Add" onBtnClick={submit} />

 </div>
</div>
    
    </ div>)
}

export {ChangeCoverPhoto}