import { useDispatch, useSelector } from "react-redux";
import { PaymentListItem } from "../../components/payments/PaymentListItem";
import { paymentItems } from "../../constants/data";
import { useEffect, useState } from "react";
import { ViewPaymentModal } from "../../components/payments/ViewPaymentDetails";
import { useNavigate } from "react-router-dom";
import { onGetPaymentinfo } from "../../services/payment";
import Spinner from "../../components/generic/Spinner";
import { toast } from "react-toastify";
import DoNotApply from "../../components/generic/DoNotApply";

const Payments = () => {
  const dispatcher = useDispatch();
  const userschool = useSelector((state) => state.userschool);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [schoolpaments, setschoolpaments] = useState(null);

  dispatcher({ type: "set-page-title", payload: "Payments" });

  const TableHeaderItems = [
    "Payment for",
    "Status",
    "Due Date",
    "Next Due Date",
    "Action",
  ];

  const onGetPayments = async () => {
    const postData = {
      schoolname: userschool.schoolname,
    };

    setLoading(true);

    try {
      var res = await onGetPaymentinfo(postData);

      setschoolpaments(res.payment);

      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
  };
  useEffect(() => {
    onGetPayments();
  }, []);

  return (
    <div className="flex h-full   justify-center items-start mt-4 ">
      <Spinner loading={loading} />
      <div className="bg-white w-full p-6">
        <div className="flex justify-between w-full bg-gray-100 px-4">
          {TableHeaderItems.map((item) => (
            <p className="text-gray-600 m-3 text-xs font-semibold capitalize ">
              {item}
            </p>
          ))}
        </div>
        <div className="bg-gray-100 p-2">
          {userschool.schooltype == "Private School" ? (
            <div>
              {paymentItems.map((i) => (
                <PaymentListItem
                  refresh={onGetPayments}
                  payment={schoolpaments}
                  item={i}
                />
              ))}
            </div>  ) : (
            <DoNotApply />
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export { Payments };
