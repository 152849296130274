import { useForm } from "react-hook-form"
import Button from "../generic/Button"
import { passwordReg, textReg } from "../../utils/regex"
import InputField from "../generic/Inputfield"
import { toast } from "react-toastify"
import Spinner from "../generic/Spinner"
import { useState } from "react"

const AdminLogin =(props)=>{
  const [loading, setLoading] = useState(false);

    const {control, handleSubmit}=useForm()
const submit=(data)=>{

  setLoading(true)
if (data.user=='admin' && data.pass=='12345'){


  setTimeout(() => {
    props.showaddmodal(false)

    localStorage.setItem('isadminauth', JSON.stringify(true));
  }, 1500);
   


}
else{


    toast.error("Invalid Credentials")
    setLoading(false )
}


}


    return(<div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#000000a9] justify-center items-center ">
      <Spinner loading={loading} />

    <div className='bg-white flex flex-col rounded h-[80vh] w-[80vw] justify-center items-center p-10 '>    
    
    
    <h2 className='text-primary'>Login as an administrator </h2>
     
    <div className='bg-white rounded  justify-center w-[30vw] p-10 '>  

    <InputField
        name="user"
        title="User"
        placeholder="Enter user"
        control={control}
        rules={{
          required: "user is required",
          pattern: {
            value: textReg,
            message: "Invalid user",
          },
        }}
      />
     <InputField
                name="pass"
                title="Password"
                placeholder="Enter password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                type={"password"}
              />

    </div>
      
    
    
    <div className='w-full  flex  gap-3 justify-center'> 
    
         
     <Button width={55}  text="Login" onBtnClick={handleSubmit(submit)} />
    
    </div>
    </div>
    
    
    
    
            
        </div>)
}

export{AdminLogin}