import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import Button from "../generic/Button";
import { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { onUpdatePaymentinfo } from "../../services/payment";
import { toast } from "react-toastify";
import Spinner from "../generic/Spinner";
import { TbFileInvoice } from "react-icons/tb";
import { FaSchool } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import { IoMdDownload } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { logo } from "../../constants/imports";

const ViewPaymentModal = (props) => {
  const userschool = useSelector((state) => state.userschool);
  const authuser = useSelector((state) => state.authuser);
  const [amount, setamount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msg, setmsg] = useState("");
  const { toPDF, targetRef } = usePDF({
    filename: "Quality Assurance Receipt.pdf",
  });
  const navigate = useNavigate();
  const [componentProps, setcomponentProps] = useState({});
  const [completeProps, setcompleteProps] = useState({});

  useEffect(() => {
    const array = userschool?.schoolcategories?.split(",");
    console.log(array);

    if (array?.length == 1) {
      // check if only nursery or only secondary
      // Note : Nusery not Nusery
      if (array[0] == "Nusery") {
        setamount(1070000); //10,000
      } else if (array[0] == "Secondary") {
        setamount(3620000); //36,200
      } else if (array[0] == "Primary") {
        setamount(3600000); //36,200
      }
    } else if (array?.length == 2) {
      if (array.includes("Nusery") && array.includes("Primary")) {
        setamount(2090000); // 20,900
      } else {
        setamount(4650000); //46,500
      }
    } else if (array?.length == 3) {
      setamount(4650000); //46,500
    }
  }, [userschool]);

  useEffect(() => {
    setcomponentProps({
      email: authuser.email,
      amount: amount,
      metadata: {
        name: userschool.schoolname,
        phone: authuser.phone,
      },
      publicKey: "pk_live_6ed66a537bf91aac63242406bc39989ea0b97966",
      text: "Pay Now",
      onSuccess: async () => {
        await updatePaymentinfo();
      },

      onClose: () => toast.error("Payment Canceled"),
    });

    setcompleteProps({
      email: authuser.email,
      amount:
        Math.abs(
          parseInt(props.payment?.amount.replace(/,/g, "")) - amount / 100
        ) * 100,
      metadata: {
        name: userschool.schoolname,
        phone: authuser.phone,
      },
      publicKey: "pk_live_6ed66a537bf91aac63242406bc39989ea0b97966",
      text: "Complete payment Now",
      onSuccess: async () => {
        await updatePaymentinfo();
      },

      onClose: () => toast.error("Payment Canceled"),
    });
  }, [amount]);

  const updatePaymentinfo = async () => {
    const postData = {
      schoolname: userschool.schoolname,
      date: new Date().toString(),
      amount: (amount / 100).toLocaleString(),
    };
    setLoading(true);
    try {
      var response = await onUpdatePaymentinfo(postData);
      toast(response.message);
      setLoading(false);

      props.refresh();
      props.setshow(false);
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (parseInt(props.payment?.amount.replace(/,/g, "")) != amount / 100) {
      setmsg(
        `Incomplete payment, remaining balace due to added school categories`
      );
    }
  }, [props.payment, amount]);
  return (
    <div className=" fixed z-50 w-screen h-[100vh] top-0 left-0 flex bg-[#00000073] justify-center   items-center ">
      <Spinner loading={loading} />
      <div className="bg-white rounded  w-[80vw] md:w-[40vw] p-5 relative flex flex-col ">
        <MdCancel
          onClick={() => {
            props.setshow(false);
          }}
          size={20}
          className="inline text-primary bg-white rounded absolute self-start top-5 right-5"
        />

        <div className="  overflow-y-scroll  h-[70vh] flex justify-start items-start ">
          <div
            ref={targetRef}
            className="bg-white  flex flex-col items-center item-center  background-overlay  text-sm  text-gray-700 p-3 py-1"
          >
            <div className="bg-primary font-semibold text-white flex justify-center items-center w-[100px] shadow rounded-lg p-2">
              {(parseInt(props.payment?.amount.replace(/,/g, "")) ==  (amount / 100 ) )? "PAID" : "NOT PAID"}
            </div>
            <hr />

            <div className="w-full flex  justify-end   ">
              <img className="w-[6rem] inline self-center " src={logo} />
              <div className="w-[45%] flex flex-col justify-end items-end">
                <h1 className="text-2xl font-medium text-gray-500 ">
                  Schoolverse
                </h1>
                <p className="text-xs font-light ">Heritage Economic </p>
                <p className="text-xs font-light ">
                  Development Innovation Limited,
                </p>
                <p className="text-xs font-light ">ghc.hedit@gmail.com</p>
              </div>
            </div>

            <div className=" flex w-full ">
              <div className="flex w-[60%]   flex-col justify-start items-start">
                <p className="text-xs font-light ">Received for </p>
                <p className="text-xs font-semibold ">
                  DEPARTMENT OF QUALITY ASSURANCE
                </p>
                <p className="text-xs font-light ">
                  Osun state Government, Secretariat, Abeere, Osogbo, Osun state
                  0803 393 7608
                </p>
              </div>

              <div className="flex w-[50%] flex-col justify-end items-end">
                <p className="text-xs font-light ">Invoiced: Hv38mcodm67 </p>
                <p className="text-xs font-light ">
                  Date Issued: Mon, January 1, 2024
                </p>
                <p className="text-xs font-light ">
                  Payment due on: Mon, January 1, 2024
                </p>
                <p className="text-sm font-bold ">
                  ₦
                  {`${
                    props.payment
                      ? props.payment.amount
                      : (amount / 100).toLocaleString()
                  }`}
                </p>
              </div>
            </div>
            <hr />
            <div className=" flex w-full my-2">
              <p className="text-xs font-light ">
                Payment Title:{" "}
                <b className="font-bold">
                  2024 QUALITY ASSURANCE ASSESSMENT FEE: NGN{" "}
                  {`${
                    props.payment
                      ? props.payment.amount
                      : (amount / 100).toLocaleString()
                  }`}
                </b>{" "}
              </p>
            </div>
            <hr />
            <div className=" flex w-full ">
              <div className="flex w-[60%]   flex-col justify-start items-start">
                <p className="text-xs font-light ">Invoiced To:</p>
                <p className="text-xs font-semibold uppercase">
                  {userschool.schoolname}{" "}
                </p>
                <p className="text-xs font-light "> {userschool.address}</p>
              </div>

              <div className="flex w-[50%] flex-col justify-end items-end">
                <p className="text-xs font-light ">Date & time of payment</p>
                <p className="text-xs font-light ">
                  {props.payment
                    ? ` Date Paid: ${props.payment.date}`
                    : "NOT PAID"}
                </p>
              </div>
            </div>
            <hr />

            <div className="flex justify-between w-full">
              <div className="border border-black w-[30%] p-4 text-xs">
                {" "}
                School type:{" "}
              </div>
              <div className="border flex justify-start items-start border-black w-[70%] p-4">
                {" "}
                <p>
                  {userschool.schoolcategories.split(",").map((i) => (
                    <span className="px-2 text-xs rounded   justify-center items-center inline py-1 m-1 bg-rose-200">
                      {" "}
                      {i}
                    </span>
                  ))}
                </p>
              </div>
            </div>

            <hr />
          </div>
        </div>
        {props.payment && (
          <>
            {parseInt(props.payment?.amount.replace(/,/g, "")) ==
            amount / 100 ? (
              <Button
                text={
                  <>
                    <IoMdDownload className="inline" /> Download Receipt
                  </>
                }
                onBtnClick={() => toPDF()}
              />
            ) : (
              <>
                <p className=" text-center text-xs mt-2 text-rose-400">{msg}</p>{" "}
                <p className=" text-center text-xs mt-2  text-rose-400">
                  Actual amount ₦ {(amount / 100).toLocaleString()}
                </p>{" "}
                <p className=" text-center text-xs mt-2  text-rose-400">
                  Amount paid ₦ {props.payment.amount}
                </p>{" "}
                <p className=" text-center text-xs mt-2 font-semibold text-rose-400">
                  Balance ₦{" "}
                  {Math.abs(
                    parseInt(props.payment?.amount.replace(/,/g, "")) -
                      amount / 100
                  ).toLocaleString()}
                </p>{" "}
                <PaystackButton
                  className="paystack-button"
                  {...completeProps}
                />
              </>
            )}
          </>
        )}
        {!props.payment && (
          <PaystackButton className="paystack-button" {...componentProps} />
        )}
      </div>
    </div>
  );
};

export { ViewPaymentModal };
