import axios from "axios";
import { baseURL } from "../utils/URL";

const onRegisterSchool = async (formdata) => {
  try {
    const response = await axios.post(`${baseURL}/createschool`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateSchool = async (formdata) => {
  try {
    const response = await axios.post(`${baseURL}/updateschool`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};



const onGetSchool = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getschool`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onVerifyStaff = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updatestaffuser`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};


const onUpdateSchoolStructure = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updateschoolstructure`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetSchoolClasses = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getschoolclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onDeleteFeeStruct = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/deleteunwantedfeestruct`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onDeleteSchool = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/deleteschool`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
  
const onUpdateLocation = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updatelocation`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateClass = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updateclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onAddClass = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/addclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onDeleteClass = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/deleteclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdatestaff = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updatestaff`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdatestudents = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updatestudents`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdatelink = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/editlink`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetAllSchool = async (postData) => {
  try {
    const response = await axios.get(`${baseURL}/getallschools`);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onExportAlldata = async (postdata) => {
  try {
    const response = await axios.post(
      `${baseURL}/downloadschooldata`,
      postdata
    );
     const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'example.txt'; // The name of the downloaded file
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onExportschooldata = async (postdata) => {
  try {
    const response = await axios.post(`${baseURL}/downloaddata`, postdata);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'example.txt'; // The name of the downloaded file
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onUploadCover = async (formdata) => {
  try {
    const response = await axios.post(`${baseURL}/uploadcover`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

export {
  onRegisterSchool,
  onGetAllSchool,
  onDeleteSchool,
  onUpdateLocation,
  onUpdatelink,
  onExportschooldata,
  onUpdatestudents,
  onUpdateSchoolStructure,
  onUpdateSchool,
  onExportAlldata,
  onUpdatestaff,
  onDeleteClass,
  onUploadCover,
  onVerifyStaff,
  onDeleteFeeStruct,
  onUpdateClass,
  onGetSchoolClasses,
  onGetSchool,
  onAddClass,
};
