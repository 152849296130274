import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/generic/Button";
import AddFacilitymodal from "../../components/facilities/Addfacilitymodal";
import { useEffect, useState } from "react";
import Spinner from "../../components/generic/Spinner";
import { onGetAllfacility } from "../../services/facility";
import { FacilityItem } from "../../components/facilities/FacilityItem";
import { FaTrash } from "react-icons/fa";
import { PiEmptyThin } from "react-icons/pi";
import { ConfirmDelete } from "../../components/facilities/DeleteConfirm";
import { onGetAllMessages } from "../../services/user";
import { toast } from "react-toastify";
import MessageItem from "./message/MessageItem";
import { FaArrowLeft, FaS } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { CiInboxIn } from "react-icons/ci";
import { CiInboxOut } from "react-icons/ci";
import { IoIosRefresh } from "react-icons/io";

import ComposeMsg from "./message/ComposeMsg";
import { CiCircleInfo } from "react-icons/ci";

export default () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const facilities = useSelector((state) => state.facilities);
  const [Checked, setchecked] = useState([]);
  const school_ = useSelector((state) => state.userschool);
  const [messages, setmessages] = useState([]);
  const [showreply, setshowreply] = useState(false);
  const [showdeletefacmodal, setshowdeletefacmodal] = useState(false);
  var authuser = useSelector((state) => state.authuser);
  const [active, setactive] = useState("All");
  const [activetab, setactivetab] = useState("Gov. Update");
  const [selectbedfac_, setselectedfac_] = useState({});
  var school = useSelector((state) => state.authuser.school);
  const [renderedlist, setrenderedlist] = useState([]);
  const [selectedmsg, setselectedmsg] = useState({});
  const [showdeatilsmsg, setshowdeatilsmsg] = useState(false);
  const [info, setinfo] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    messages.reverse();
    setrenderedlist(messages);
  }, [messages]);

  const ViewMsg = () => (
    <>
      <div className="mx-10 pt-10">
        <div className="bg-white text-xs p-2 ">
          <div className="flex ">
            {selectedmsg.issent ? (
              <CiInboxOut
                size={30}
                className=" inline text-primary font-light"
              />
            ) : (
              <CiInboxIn
                size={30}
                className=" inline text-primary font-light"
              />
            )}
            <div className="flex flex-col">
              <p className=" text-xs font-medium mx-4 ">
                From {selectedmsg.user ? selectedmsg.user : selectedmsg.from}{" "}
                {selectedmsg.senderType} to {selectedmsg.to}
              </p>

              <p className="text-[10px] font-light mx-4 ">{selectedmsg.time}</p>
            </div>
          </div>
          <p>
            <FaArrowLeft
              className=" ml-3 m-3 mb-3 inline  text-primary"
              size={20}
              onClick={() => setshowdeatilsmsg(false)}
            />
            Sent from{" "}
            <span className="bg-white border text-xs font-semibold text-primary border-gray-400 rounded p-2">
              {selectedmsg.from}
            </span>
          </p>

          <p className="text-gray-500 text-lg mx-4 ">{selectedmsg.title}</p>
          <p className="text-gray-500 text-md mx-4 bg-gray-100 p-3 ">
            {selectedmsg.content ? selectedmsg.content : selectedmsg.message}
          </p>
        </div>
        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={55}
            text="Reply"
            onBtnClick={() => {
              setshowreply(true);
            }}
          />
        </div>
      </div>
      {showreply && <ComposeMsg show={setshowreply} message={selectedmsg} />}
    </>
  );
  const NoMessage = () => (
    <div className="flex  w-full flex-col justify-center items-center  p-4">
      <PiEmptyThin className="text-primary" size={30} />
      <p className="text-primary font-light text-2xl ">No Messages yet</p>
    </div>
  );

  const getallmsg = async () => {
    let postdata = { user: school_.schoolname };
    setLoading(true);
    try {
      var response = await onGetAllMessages(postdata);
      console.log(response.data);
      var emptarr = [];
      for (let messages in response.data) {
        emptarr.push(response.data[messages]);
      }

      setmessages(emptarr);
    } catch (e) {
      toast.error(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const FilterItem = ({ item, click }) => (
    <div
      onClick={() => {
        setactive(item);
        click();
      }}
      className={`${
        active == item
          ? "bg-primary font-semibold flex justify-center items-center text-white"
          : "  border border-gray-300"
      } cursor-pointer rounded  text-sm p-2  mx-3`}
    >
      {item}
    </div>
  );
  const FilterItem2 = ({ item, info_, click }) => (
    <div
      onClick={() => {
        setactivetab(item);
        setinfo(info_);
        click();
      }}
      className={`${
        activetab == item
          ? "bg-gray-900 font-semibold flex justify-center items-center text-white"
          : "  border border-gray-400"
      } cursor-pointer rounded  text-sm p-2  mx-3`}
    >
      {item}
    </div>
  );

  useEffect(() => {
    getallmsg();
  }, []);
  dispatcher({ type: "set-page-title", payload: "Notifications" });
  var [showaddmodal, setshowaddmodal] = useState(false);
  return (
    <>
      {!showdeatilsmsg ? (
        <>
          <Spinner loading={loading} />

          <div  className="flex justify-center flex-col items-center">
            <div className="flex justify-center my-2">
              <FilterItem2
                click={() => {
                  setrenderedlist(messages);
                }}
                info_={"Messages from Gov. officials"}
                item="Gov. Update"
              />
              <FilterItem2
                click={() => {
                  setrenderedlist(messages);
                }}
                info_={"Messages from staff"}
                item="Messages"
              />
            </div>
          {info!=""&&  <p className="text-sm my-4 ">
              {" "}
              <CiCircleInfo className="inline " /> {info}{" "}
            </p>}
          </div>

          <div className="border flex border-gray-400  p-2 mx-10 items-center">
            <FilterItem
              click={() => {
                setrenderedlist(messages);
              }}
              item="All"
            />
            <FilterItem
              click={() => {
                setrenderedlist(messages.filter((n) => !n.issent));
              }}
              item="Inbox"
            />
            <FilterItem
              click={() => {
                setrenderedlist(messages.filter((n) => n.issent));
              }}
              item="Sent messages"
            />
            <IoIosRefresh
              onClick={async () => {
                getallmsg();
              }}
              size={20}
              className="inline text-primary"
            />
          </div>
          <div className="p-10 bg-gray-100 border border-gray-500 mx-10 h-[70vh] overflow-y-scroll overflow-x-hidden">
            {renderedlist.length > 0 ? (
              <div className="">
                {renderedlist.map((item, key) => (
                  <MessageItem
                    click={() => {
                      setselectedmsg(item);
                      setshowdeatilsmsg(true);
                    }}
                    key={key}
                    item={item}
                  />
                ))}
              </div>
            ) : (
              <div className="bg-white p-10 h-full">{<NoMessage />}</div>
            )}
          </div>
        </>
      ) : (
        <ViewMsg />
      )}
    </>
  );
};
