import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import { schooladminitems } from "../../constants/data";
import Button from "../generic/Button";
import { SchoolNavItems } from "./SchoolNavItems";
import { SelectedHero } from "./SelectedHero";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { onExportschooldata } from "../../services/school";
import Spinner from "../generic/Spinner";
import { MdDelete } from "react-icons/md";
import DeleteSchoolModal from "../../pages/ProtectedRoutes/DeleteSchoolModal";

const SelectedSchool = () => {
  const school_ = useSelector((state) => state.selectedSchool);
  const [loading, setLoading] = useState(false);
  const [showedelete, setshowedelete] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (school_.schoolname == undefined) {
      navigate("/admin");
      return;
    }
  }, []);

  const Export = async () => {
    setLoading(true);
    let postData = {
      school: school_.schoolname,
    };
    var res = await onExportschooldata(postData);
    setLoading(false);
  };
  return (
    <>
      <Spinner loading={loading} />

      {showedelete && <DeleteSchoolModal show={setshowedelete} />}

      <SelectedHero />
      <FaArrowLeft
        className=" ml-3 m-3 mb-3 text-primary"
        size={30}
        onClick={() => navigate(-1)}
      />

      <div className="bg-customGray flex flex-col p-4 m-4">
        <div className=" place-self-end self-end ">
          <Button
            text="Export data"
            onBtnClick={() => {
              Export();
            }}
          />
        </div>
        <div className=" bg-white  flex justify-center flex-col items-center   text-sm rounded-b-md p-3">
          <h1 className=" font-semibold text-lg text-primary ">
            Link to School Website/Social media
          </h1>

          <p className=" text-[15px] p-5">
            <a className=" text-blue-400  " href={school_.link}>
              <FaExternalLinkAlt
                className="text-primary inline  mx-2"
                size={20}
              />
              Visit{" "}
            </a>
          </p>
        </div>

        <div className="grid  mx-20 grid-cols-2 m-6 gap-6">
          {schooladminitems.map((item) => (
            <SchoolNavItems item={item} />
          ))}
          <Button
            outlined
            text={
              <div className="border border-transparent uppercase ">
                {" "}
                <MdDelete size={30} className="inline  text-rose-400" /> Delete
                School
              </div>
            }
            onBtnClick={() => {
              setshowedelete(true);
            }}
          />
        </div>
      </div>
    </>
  );
};
export { SelectedSchool };
