import { useState } from "react"

const useCategoryPickerhook =()=>{
    
    const [selected, setselected]=useState([])


    return {selected, setselected}
}

export
 {useCategoryPickerhook}