import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"



const SchoolItem =(props)=>{
var dispatcher=useDispatch()
var navigate=useNavigate()


    return (<div onClick={()=>{
        dispatcher({type:'set-selectedSchool',payload:props.item})
         navigate('/admin/school')

    }} className="bg-white rounded shadow  border   hover:border-primary pb-2 ">

<img className="w-full border border-primary h-[250px] bg-cover " alt="School logo empty" src={props.item.schoollogo}/>
<p className="font-semibold  text-primary ml-3">{props.item.schoolname}</p>
<p className="font-light text-sm ml-3 text-primary ">{props.item.address}</p>
<p className="font-light  text-sm ml-3 text-primary ">{props.item.creatorrole} : {props.item.user} </p>
 
    </div>)
}

export{SchoolItem}