import { set, useForm } from "react-hook-form";
import InputField from "../generic/Inputfield";
import { addressRegex, numReg, textReg, urlregex } from "../../utils/regex";
import { Rolepicker } from "../ui/Rolepicker";
import { usePickerhook } from "../../hooks/usePickerhook";
import { Categorypicker } from "../ui/Categorypicker";
import { useCategoryPickerhook } from "../../hooks/useCategoryPickerhook";
import { LogoUploader } from "../generic/LogoUploader";
import { usePhotoUpload } from "../../hooks/usePhotoUploadhook";
import Button from "../generic/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../generic/Spinner";
import {
  onDeleteFeeStruct,
  onRegisterSchool,
  onUpdateSchool,
  onUpdateSchoolStructure,
} from "../../services/school";
import { onGetUser } from "../../services/user";
import { Addreespick } from "../generic/Addresspick";
import { LocationModal } from "./AddlocationModal";
import { useLocationHook } from "../../hooks/useLocationHook";
import { ListPicker } from "../ui/ListPicker";

const RegisterSchool = ({ isedit }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loactionpicker, setlocationpicker] = useState(false);
  const { control, handleSubmit } = useForm();
  const school_ = useSelector((state) => state.userschool);

  const locatioposhook = useLocationHook();
  var photouploadhook = usePhotoUpload();
  const dispatcher = useDispatch();
  const schooltypes = [
    { code: "PrivateSchool", name: "Private School" },
    { code: "StateGovernmentSchool", name: "State Government School" },
    { code: "Federal GovernmentSchool", name: "Federal Government School" },
    { code: "ForceSchool", name: "Force School" },
  ];
  const [address, setAddress] = useState("");
  const authuser = useSelector((state) => state.authuser);
  const rolepickerhook = usePickerhook();
  const categorypicker = useCategoryPickerhook();
  const schooltypehook = usePickerhook();

  dispatcher({ type: "set-page-title", payload: "Register School" });

  const updateuser = async () => {
    const formdata2 = { email: authuser.email };
    // get updated user details=school included
    let userres = await onGetUser(formdata2);

    dispatcher({ type: "set-auth-user", payload: userres });

    localStorage.setItem(`UserData`, JSON.stringify(userres));
  };

  useEffect(() => {
    if (isedit) {
      categorypicker.setselected(school_.schoolcategories.split(","));
      schooltypehook.setPickedRole({
        name: school_.schooltype,
        code: school_.schooltype,
      });
      rolepickerhook.setPickedRole(school_.creatorrole);
      setAddress(school_.address);
    }
  }, []);
  const onSubmit = async (data) => {
    console.log(locatioposhook.marker_pos);
    if (categorypicker.selected.length < 1) {
      toast.error("Choose at least one category");
      return;
    }

    if (!photouploadhook.file && !isedit) {
      toast.error("School logo is required");
      return;
    }

    if (!rolepickerhook.pickedRole) {
      toast.error("You role is required");
      return;
    }
    if (!schooltypehook.pickedRole) {
      toast.error("School type is required");
      return;
    }

    if (address == "") {
      toast.error("Address can't be blank ");
      return;
    }

    const formdata = new FormData();
    formdata.append("schoolname", data.schoolname);
    formdata.append("address", address);
    // formdata.append("staffs", data.staffno);
    // formdata.append("students", data.studno);
    formdata.append("yearfounded", data.yearfounded);
    formdata.append("link", data.link ? data.link : "");
    formdata.append("schooltype", schooltypehook.pickedRole.name);
    formdata.append("user", authuser.email);
    formdata.append("location", locatioposhook.marker_pos);
    formdata.append("coverphoto", isedit ? school_.coverphoto : "");
    formdata.append(
      "schoollogo",
      !isedit
        ? photouploadhook.file
        : photouploadhook.file
        ? photouploadhook.file
        : school_.schoollogo
    );
    formdata.append("schoolcategories", categorypicker.selected);
    formdata.append("creatorrole", rolepickerhook.pickedRole);

    if (!authuser.firstname) {
      toast("You must login to register a school");
      navigate("/login");
      return;
    }
    console.log(formdata);
    setLoading(true);

    if (!isedit) {
      var res = await onRegisterSchool(formdata);

      setLoading(false);
      toast(res ? res.message : " An error occured");

      if (res.success) {

        setLoading(true)
        await updateuser();
        setLoading(false)

        navigate("/dashboard");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      var res = await onUpdateSchool(formdata);
      var schooloriginalcatarray = school_.schoolcategories.split(",");
      var selectedcats = categorypicker.selected;
      const difference = [
        ...schooloriginalcatarray.filter((x) => !selectedcats.includes(x)),
        ...selectedcats.filter((x) => !schooloriginalcatarray.includes(x)),
      ];
      var formx = new FormData();
      formx.append("schoolname", data.schoolname);
      formx.append("schoolcategories", selectedcats);

      var updateres = await onUpdateSchoolStructure(formx);
      var formy = new FormData();
      formy.append("schoolname", data.schoolname);
      formy.append("presentCats", selectedcats);
      await onDeleteFeeStruct(formy);

      setLoading(false);
      toast(res ? res.message : " An error occured");

      await updateuser();

      navigate("/dashboard");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <div className="flex flex-col justify-center items-center p-10 bg-white">
      <Spinner loading={loading} />
      {loactionpicker && (
        <LocationModal
          setAddress={setAddress}
          Hook={locatioposhook}
          cancel={setlocationpicker}
        />
      )}

      {!isedit ? (
        <>
          <p className="font-light text-[20px] p-2">Welcome to SchoolVerse</p>
          <p className=" font-light text-black text-[14px] p-4">
            This is your first login, please fill in the following details
          </p>
        </>
      ) : (
        <p className="font-light text-[20px] p-2">Edit School details</p>
      )}
      <div className="min-w-[60%] border border-primary p-10 grid md:grid-cols-2 gap-6  items-center  content-center">
        <InputField
          name="schoolname"
          title="School Name"
          placeholder="Enter School name"
          control={control}
          isDisabled={isedit ? true : false}
          def_value={isedit ? school_.schoolname : ""}
          rules={{
            required: "School name is required",
            pattern: {
              value: textReg,
              message: "Invalid School name",
            },
          }}
        />
        <Rolepicker hook={rolepickerhook} />
        <ListPicker
          title="School Type"
          list={schooltypes}
          hook={schooltypehook}
        />
        <Categorypicker hook={categorypicker} />

        <LogoUploader hook={photouploadhook} title="School Logo" />

        {/* <InputField
          name="staffno"
          title="Number of staff"
          placeholder="Enter number of staff"
          control={control}
          rules={{
            required: "Number of staff is required",
            pattern: {
              value: numReg,
              message: "Invalid number",
            },
          }}
        /> */}
        <InputField
          name="link"
          def_value={
            isedit ? (school_.link != "undefined" ? school_.link : "") : ""
          }
          title="School website/Social media link (Optional)"
          placeholder="Enter link"
          control={control}
          // rules={{
          //   required: "Website is required",
          //   pattern: {
          //     value: urlregex,
          //     message: "Invalid link",
          //   },
          // }}
        />

        {/* <InputField
          name="studno"
          title="Number of students"
          placeholder="Enter number of students"
          control={control}
          rules={{
            required: "Number of students is required",
            pattern: {
              value: numReg,
              message: "Invalid number",
            },
          }}
        /> */}

        <InputField
          name="yearfounded"
          title="Year founded"
          def_value={isedit ? school_.yearfounded : ""}
          placeholder="Enter year"
          control={control}
          rules={{
            required: "This is required",
            pattern: {
              value: numReg,
              message: "Invalid year",
            },
          }}
        />

        <Addreespick
          cord={address}
          onclick={() => {
            setlocationpicker(true);
          }}
          title="Enter address"
        />

        <div className="mt-4">
          {!isedit ? (
            <Button text="Proceed" onBtnClick={handleSubmit(onSubmit)} />
          ) : (
            <Button text="Save" onBtnClick={handleSubmit(onSubmit)} />
          )}
        </div>
      </div>
    </div>
  );
};

export { RegisterSchool };
