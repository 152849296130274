import { useState } from "react";
import { FaDeleteLeft } from "react-icons/fa6";
import { MdDelete, MdSend } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteStaffModal from "../Admin/DeleteStaffModal";

export default (props) => {
  var dispatcher = useDispatch();
  var navigate = useNavigate();
  const  [showdeletestaff, setshowdeletestaff] =useState(false)

  return (
    <div
      onClick={() => {
      //  dispatcher({ type: "set-selectedStaff", payload: props.item });
      }}
      className="bg-white rounded shadow  border   hover:border-primary pb-2 "
    >
      {showdeletestaff && <DeleteStaffModal refresh ={props.refresh} item={props.item} show={setshowdeletestaff}/>}
      <img
        className="w-full border bg-cover border-primary h-[200px]  "
        alt="Staff photo empty"
        src={props.item.passport}
      />
      <p className="font-semibold  text-primary ml-3">
        {props.item.firstname} {props.item.lastname}{" "}
      </p>
      <p className="font-light text-sm ml-3 text-primary ">
        {props.item.email}
      </p>
      <p className="font-light text-sm ml-3 text-primary ">
        {props.item.inspectortype}
      </p>
      <p className="font-light text-sm ml-3 text-primary ">
        {" "}
        LGAs{props.item.lgas}
      </p>
      <p className="font-light text-xs ml-3 text-primary ">
        {" "}
        DOB : {props.item.dob}
      </p>

      <p className="font-light text-sm ml-3 text-gray-500 ">
        {props.item.schhool}
      </p>
      <div className="flex justify-between m-2  items-center ">
        <button
          onClick={() => {setshowdeletestaff(true)}}
          className="bg-amber-500   text-white flex text-sm justify-center items-center p-1 rounded"
        >
          <MdDelete className="text-white inline mr-1" />
          Delete staff
        </button>

        <button
          onClick={() => {}}
          className="bg-amber-500 text-sm  text-white flex justify-center items-center p-1 rounded"
        >
          <MdSend className="text-white inline mr-1" />
          Send message
        </button>
      </div>
    </div>
  );
};
