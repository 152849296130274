import { useState } from "react";
import { FaCheck, FaEye, FaTrash, FaUpload } from "react-icons/fa";
import { useDispatch } from "react-redux";

const Certitem = (props) => {
var status=''
var dispatcher =useDispatch()
    var itemstyle=()=>{

if(props.item.url != ""){

if (props.item.title.includes("verified")){

    status="Verified"
    return "bg-primary"
}

 
else if (!props.item.title.includes("verified") ){

    status="Under Review"

    return "bg-blue-500"
}
 
}
else{
    status="Not Uploaded"

    return "bg-rose-500"
}



    }



  return (
    <div
      className={` my-2 rounded  flex justify-between items-center p-3 w-full ${  itemstyle() } `}
    >
      <p className="text-sm w-[33%] truncate text-white font-semibold ">
        {props.item.title}
      </p>{" "}
      <p className="text-sm  text-center w-[33%] truncate text-white font-semibold ">
        {status}
      </p>{" "}
      <div className=" flex w-[33%]  justify-end mr-3 gap-2 text-white">
        {" "}
      {(status=='Verified' || status=='Under Review') && <><FaCheck   className="mx-1  active:bg-black " />  
       </>}
      
       
        
      </div>
    </div>
  );
};

export { Certitem };
