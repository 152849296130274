import { FaMobile } from "react-icons/fa"
import Button from "../components/generic/Button"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const Mobile =()=>{
var navigate =useNavigate()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
      });
    useEffect(()=>{
        if(isDesktopOrLaptop){

            navigate("/dashboard");
        }

        
    else{
        console.log("not desktop")
      navigate('/mobile')
      
          }

        },[])
    
return(<>
<div className=" ">
    
    <div className="w-full p-4  flex justify-center items-center bg-primary h-[100vh] flex-col">

       <FaMobile  size={30} className="text-white "/>  <p className="text-lg w-full text-black text-center font-semibold"> Enable desktop view for a better user expereience</p>
       <Button width={55}  text="Refresh" onBtnClick={ ()=>{
            window.location.reload();


       }} />


       




    </div>
    </div>
</>)


}

export{Mobile}