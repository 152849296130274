import { MdDashboard } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { CiReceipt } from "react-icons/ci";
import { FaCertificate, FaComment } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { FcRating } from "react-icons/fc";
import { FaMapLocation } from "react-icons/fa6";
import { MdOutlineReviews } from "react-icons/md";
import { PiChalkboardTeacher } from "react-icons/pi";
import { PiUserListBold } from "react-icons/pi";

const paymentItems = [
  {
    title: "Quality Assurance Assessment Fee",
    dueDate: new Date().toDateString(),
    nextDueDate: new Date().toDateString(),
  },
];
const itemstyle = { size: 20, color: "#fff" };
const categories = [
  { name: "Nusery", id: "nusery" },

  { name: "Primary", id: "pprimary" },
  { name: "Secondary", id: "secondary" },
];
const roles = [
  {
    name: "Principal",
    roleid: "Principal",
  },

  {
    name: "Headmaster",
    roleid: "Headmaster",
  },

  {
    name: "Headmistress",
    roleid: "Headmistress",
  },
];

const States = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
const LGAs = [
  "Atakumosa West",
  "Atakumosa East",
  "Ayedaade",
  "Ayedire",
  "Boluwaduro",
  "Boripe",
  "Ede South",
  "Ede North",
  "Egbedore",
  "Ejigbo",
  "Ife North",
  "Ife South",
  "Ife-Central",
  "Ife-East",
  "Ifelodun",
  "Ila",
  "Ilesa-East",
  "Ilesa-West",
  "Irepodun",
  "Ifedayo",
  "Ife-East area office",
  "Irewole",
  "Isokan",
  "Iwo",
  "Obokun",
  "Odo-Otin",
  "Ola Oluwa",
  "Olorunda",
  "Oriade",
  "Orolu",
  "Osogbo",
];

const schoolfacilitycategory = [
  { name: "Classroom", type: "classroom" },
  { name: "Playground", type: "playground" },
  { name: "Sport Facility", type: "sports" },
  { name: "Labouratory", type: "lab" },
  { name: "Mosque", type: "mosque" },
  { name: "Chapel", type: "chapel" },
  { name: "ICT", type: "ict" },
  { name: "Boarding Facility", type: "boarding" },
];

const schooladminitems = [
  { title: "Certificates", path: "/admin/certificates" },
  { title: "Fees", path: "/admin/fees" },
  { title: "Facilities", path: "/admin/facilities" },
  { title: "Payments", path: "/admin/payments" },
  { title: "Map location", path: "/admin/mapadmin" },
];

const sidebaritems = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: (
      <MdDashboard
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },

  {
    name: "Facilities",
    path: "/dashboard/facilities",
    icon: (
      <IoIosSettings
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Fees",
    path: "/dashboard/fees",
    icon: (
      <CiReceipt
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Class Teacher",
    path: "/dashboard/classteacher",
    icon: (
      <PiChalkboardTeacher
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },

  {
    name: "Staff",
    path: "/dashboard/staff",
    icon: (
      <PiUserListBold
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Certificates",
    path: "/dashboard/certificates",
    icon: (
      <FaCertificate
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Map",
    path: "/dashboard/map",
    icon: (
      <FaMapLocation
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Review & Rating ",
    path: "/dashboard/review",
    icon: (
      <MdOutlineReviews
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
 
  {
    name: "Payments",
    path: "/dashboard/payments",
    icon: (
      <MdOutlinePayment
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  
];

export {
  sidebaritems,
  States,
  LGAs,
  paymentItems,
  roles,
  categories,
  schooladminitems,
  schoolfacilitycategory,
};
