import { baseURL } from "../utils/URL";
import axios from "axios";

 

 

const onLogin = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/login`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};


const onSignup = async (postData ) => {

    try {
        const response = await axios.post(`${baseURL}/signup`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
        
};

export { onLogin, onSignup }