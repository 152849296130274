import { useForm } from "react-hook-form";

import { addressRegex, emailReg, numReg, textReg } from "../../utils/regex";
import Button from "../generic/Button";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import { onUpdateProprietor } from "../../services/user";
import { useSelector } from "react-redux";
import Spinner from "../generic/Spinner";
import { useState } from "react";

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  var userschool = useSelector((state) => state.userschool);

  const onSubmit = async (data) => {
    console.log(data);

    let postdata = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
    };
try{

  setLoading(true)
  var response = await onUpdateProprietor({
    schoolname: userschool.schoolname,
    data: { ...postdata },
  });
  toast.success("Updated")
  setLoading(false)
  props.show(false);

}
catch(e){
toast.error(e)
  console.log(e)
  setLoading(false)

}
 
  };
  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-[#000000a7] w-[100vw] h-[100vh]">
            <Spinner loading={loading} />

      
      <div className="bg-white md:w-[30vw] rounded px-10 py-4">
        <p className="text-primary font-semibold">Edit Proprietor's details</p>
        <InputField
          name="firstname"
          title="Firstname"
          def_value={props.det.firstname}
          control={control}
          rules={{
            required: "Firstname is required",
            pattern: {
              value: textReg,
              message: "Invalid Firstname",
            },
          }}
        />

        <InputField
          name="lastname"
          title="lastname"
          def_value={props.det.lastname}
          control={control}
          rules={{
            required: "lastname is required",
            pattern: {
              value: textReg,
              message: "Invalid lastname",
            },
          }}
        />
        <InputField
          name="email"
          title="Email"
          def_value={props.det.email}
          control={control}
          rules={{
            required: "Email is required",
            pattern: {
              value: emailReg,
              message: "Invalid Email",
            },
          }}
        />
        <InputField
          name="phone"
          title="Phone"
          def_value={props.det.phone}
          control={control}
          rules={{
            required: "Phone is required",
            pattern: {
              value: numReg,
              message: "Invalid phone",
            },
          }}
        />

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.show(false);
            }}
          />
          <Button width={55} text="Save" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};
