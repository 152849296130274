import { BsCardImage } from "react-icons/bs"
import { FaLocationDot } from "react-icons/fa6"
import { MdCancel } from "react-icons/md"
import { MdOutlineDoNotDisturbOnTotalSilence } from "react-icons/md";

export default(props)=>{


    return( <div className="my-4 flex justify-center items-center ">
   
    <MdOutlineDoNotDisturbOnTotalSilence size={50} className="text-primary "/>
 <p className="font-light text-md text-gray-600">
  This feature does not apply to this school type
 </p>
    </div>)
}

 