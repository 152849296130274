import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { useSelector } from "react-redux";



 
const SchoolRating =(props)=>{
    const userschool = useSelector((state) => state.userschool);

const [empt,setempt]=useState(0)
const [fill,setfill]=useState(0)
const [elem,setelem]=useState([])
const [remark,setremark]=useState("")
const accreditedStatus=useSelector((s)=>s.accreditedStatus)

useEffect(()=>{

setfill(props.num)
setempt(5-props.num)
     var elem_=[]
    for(let i=0; i<fill; i++ ){
elem_.push(<FaStar size={30} className="text-[#FFC000] p-1"/>)}
    for(let i=0; i<empt; i++ ){
elem_.push(<CiStar size={30} className="text-[#FFC000] p-1 "/>) }


switch (props.num){


    case 1:
        setremark(" Very Poor School")
        break;
    case 2:
      setremark(" Poor School")
        break;
    case 3:
        setremark(" Fair School")
        break;
    case 4:
        setremark(" Good School")
        break;
    case 5:
        setremark(" Outstanding School")
        break;

}

setelem(elem_)
},[elem])


return(<div className=" p-3 flex justify-center items-center font-bold  ">

{    userschool.schooltype =="Private School"? accreditedStatus:"ACCREDITED"}  {elem  } Score: {props.percent}%  Remark:{remark}

</div>)



}

export{

    SchoolRating
}