import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/generic/Button";
import { useEffect, useState } from "react";
import {
  onGetSchool,
  onGetSchoolClasses,
  onUpdatelink,
  onUpdatestaff,
  onUpdatestudents,
} from "../../services/school";
import { IoMdMenu } from "react-icons/io";

import { toast } from "react-toastify";
import Spinner from "../../components/generic/Spinner";
import {
  onGetAllstaffs,
  onGetProprietor,
  onGetUser,
} from "../../services/user";
import MapComponent from "../../components/ui/MapItem";
import { useLocationHook } from "../../hooks/useLocationHook";
import { IoIosRefresh } from "react-icons/io";
import { ChangeCoverPhoto } from "../../components/Dashboard/ChangeCoverModal";
import { FaEdit, FaExternalLinkAlt, FaLink } from "react-icons/fa";
import InputField from "../../components/generic/Inputfield";
import { numReg, urlregex } from "../../utils/regex";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { onGetAllcertificates } from "../../services/certificates";
import { Certitem } from "../../components/Dashboard/Certitem";
import { SchoolRating } from "../../components/Review/SchoolRating";
import { onGetSchoolReview } from "../../services/review";
import { PaymentListItem } from "../../components/payments/PaymentListItem";
import { paymentItems } from "../../constants/data";
import { onGetPaymentinfo } from "../../services/payment";
import EditProprietor from "../../components/Dashboard/EditProprietor";
import { CiLock } from "react-icons/ci";
import Sealed from "./Sealed";
import React from "react";
import DoNotApply from "../../components/generic/DoNotApply";
const AccountDashbaord = () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm();
  const [certs, setcerts] = useState();
  dispatcher({ type: "set-pagetitle", payload: "Dashboard" });
  var authuser = useSelector((state) => state.authuser);
  var userschool = useSelector((state) => state.userschool);
  const [totalstudents, settotalstudents] = useState(0);
  const [totalstaff, settotalStaffs] = useState(0);
  const [editstaff, seteditstaff] = useState(false);
  const [editlink, seteditlink] = useState(false);
  const [editstud, seteditstud] = useState(false);
  const [rating, setrating] = useState(0);
  const [reviewItems, setReviewItems] = useState([]);
  const school_ = useSelector((state) => state.userschool);
  const [schoolpaments, setschoolpaments] = useState(null);
  var categories = userschool.schoolcategories?.split(",");
  const [schoolClasses, setschoolClasses] = useState([]);
  const [allstaffs, setallstaffs] = useState([]);
  const navigate = useNavigate();

  const accreditedStatus = useSelector((state) => state.accreditedStatus);
  const [propsdetails, setpropsdetails] = useState({});
  const [userdetails, setuserdetails] = useState({});
  const getSchoolClasses = async () => {
    let postData = {
      schoolname: userschool.schoolname,
    };

    setLoading(true);
    var response = await onGetSchoolClasses(postData);
    setLoading(false);
    var emptyArr = [];
    let totalstudents_ = 0;
    Object.keys(response).map((key) => {
      emptyArr.push(response[key]);

      totalstudents_ =
        parseInt(totalstudents_) +
        (parseInt(response[key].male) + parseInt(response[key].female));
      console.log(
        parseInt(response[key].male) + parseInt(response[key].female),
        "kkk"
      );
    });

    settotalstudents(totalstudents_);

    setschoolClasses(
      emptyArr.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getallstaffs = async () => {
    setLoading(true);
    var response = await onGetAllstaffs();
    setLoading(false);

    var emptyArr = [];
    Object.keys(response).map((key) => {
      if (response[key].schhool == userschool.schoolname)
        emptyArr.push(response[key]);
    });

    setallstaffs(emptyArr);
    console.log(emptyArr);
  };

  useEffect(() => {}, []);
  const getSelectedschoolReview = async () => {
    setLoading(true);
    var formdata = { schoolname: school_.schoolname };
    var res = await onGetSchoolReview(formdata);
    setLoading(false);
    console.log(res.review_items, "....///....");
    setReviewItems(res.review_items);
  };
  const toastId = React.useRef(null);

  const getUserdetails = async () => {
    let postdata = { email: authuser.email };

    try {
      setLoading(true);
      var response = await onGetUser(postdata);
      setLoading(false);
      setuserdetails(response);

      if (!response.firstname) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast("User no longer exists...");
          localStorage.clear();
          dispatcher({ type: "set-auth-user", payload: {} });
          navigate("/");
        }
      }
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await onGetPayments();
      await getSchoolClasses();
      await getUserdetails();
      await getSelectedschoolReview();
      await getallstaffs();
      await onGetPropDet();
      setLoading(false);
    };
    init();
  }, [school_]);

  useEffect(() => {
    let sum = 0;
    let percent = 0;
    reviewItems?.map((k) => {
      sum = sum + k.score;
    });

    percent = Math.ceil((sum / 80) * 100);
    setrating(percent);
  }, [reviewItems]);

  const onGetPropDet = async () => {
    let postdata = { schoolname: userschool.schoolname };
    try {
      setLoading(true);
      var response = await onGetProprietor(postdata);
      setLoading(false);
      setpropsdetails(response.details);
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
    setLoading(false);
  };
  const onGetPayments = async () => {
    const postData = {
      schoolname: userschool.schoolname,
    };

    setLoading(true);

    try {
      var res = await onGetPaymentinfo(postData);

      setschoolpaments(res.payment);

      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
  };
  const onsavestaff = async (data) => {
    setLoading(true);
    var postdata = {
      newstaffno: data.staffnoo,
      school: userschool.schoolname,
    };

    try {
      var res = await onUpdatestaff(postdata);
      toast.success(res.message);
      setLoading(false);
      window.location.reload();
    } catch (e) {
      toast.success(e);
      setLoading(false);
    }
  };
  const getSchoolinfo = async () => {
    const formdata = { name: authuser.school };

    try {
      setLoading(true);
      //get school info
      let res = await onGetSchool(formdata);

      dispatcher({ type: "set-user-school", payload: res });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  const onsavestud = async (data) => {
    setLoading(true);
    var postdata = {
      newstudentsno: data.studnoo,
      school: userschool.schoolname,
    };

    try {
      var res = await onUpdatestudents(postdata);
      toast.success(res.message);
      setLoading(false);
      window.location.reload();
    } catch (e) {
      toast.success(e);
      setLoading(false);
    }
  };
  const onsavelink = async (data) => {
    setLoading(true);
    var postdata = {
      newlink: data.link,
      school: userschool.schoolname,
    };

    try {
      var res = await onUpdatelink(postdata);
      toast.success(res.message);
      setLoading(false);
      window.location.reload();
    } catch (e) {
      toast.success(e);
      setLoading(false);
    }
  };
  const init = async () => {
    const postdata = { school: authuser.school };
    console.log(postdata, "certificates");
    setLoading(true);
    var res = await onGetAllcertificates(postdata);
    setLoading(false);
    if (res.certicates) {
      setcerts(res.certicates);
      console.log(res.certicates);
    }

    return res.certicates;
  };

  useEffect(() => {
    getSchoolinfo();
    init();
  }, [authuser]);

  useEffect(() => {
    if (
      certs?.some(
        (i) =>
          i.title.includes("CAC Certificate") && i.title.includes("verified")
      )
    ) {
      //Accredited
      dispatcher({ type: "set-accred", payload: "ACCREDITED" });
    } else {
      dispatcher({ type: "set-accred", payload: "UNACCREDITED" });
    }
  }, [certs]);
  return (
    <div className="m-4 bg-white rounded">
      <Spinner loading={loading} />

      {userschool.locked && <Sealed />}
      <div
        style={{
          height: "150px",
          backgroundImage: `url(${
            userschool.coverphoto == ""
              ? "https://firebasestorage.googleapis./v0/b/sclverse.appspot.com/o/predefs%2Fcoverphoto.jpg?alt=media&token=447e7c08-19fd-4d9f-ac87-679bd0039793"
              : userschool.coverphoto
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {userschool.locked && (
          <div className="bg-rose-600 w-full py-2">
            <CiLock size={20} className="inline text-white" />
            <p className="text-white font-semibold inline">
              School locked by admin
            </p>
          </div>
        )}
        <div className="m-2">
          <img
            className="rounded-full  border-primary border w-[100px] h-[100px] "
            src={userschool.schoollogo}
          />
        </div>

        {
          //<img className="w-[450px] h-[150px]" src={userschool.coverphoto}/>
        }
      </div>

      <div className="rounded-bt p-4 ">
        <div className=" bg-customGray  grid md:grid-cols-2   justify-between   text-sm rounded-b-md p-3 m-2">
          <div>
            <p className="font-semibold  text-sm md:text-lg text-primary">
              {userschool.schoolname}
            </p>
            <p className="font-medium self-end   text-xs p-2 rounded">
              School address: {userschool.address}
            </p>
          </div>

          <div className="p-4 text-white font-light bg-primary justify-center items-center ">
            <p className=" text-xs font-light">School type</p>
            <p className="p-1 bg-[#fbaa1e8a] flex rounded ">
              {userschool.schooltype}
            </p>
            Year founded:
            {userschool.yearfounded} <br /> School categories :
            {categories?.map((item) => (
              <p className="inline md:p-2  p-1 md:mt-0 rounded mx-2 bg-white font-light text-xs md:text-sm  text-black ">
                {item}
              </p>
            ))}
          </div>
        </div>

        <div>
          <p className="font-bold p-2 text-white text-sm  uppercase bg-[#0303037c]">
            <SchoolRating
              percent={rating}
              num={Math.ceil((rating / 100) * 5)}
            />
          </p>
        </div>
        <div className=" bg-customGray  flex  flex-col md:flex-row items-start md:items-center md:justify-between   text-sm rounded-b-md p-3 ">
          <div>
            <p className="font-semibold text-lg text-primary">
              <p className=" text-xs font-light">Account creator</p>
              {userschool.creatorrole}{" "}
            </p>
            <ul className="font-light truncate">
              <li>
                Name:{userdetails.firstname} {userdetails.lastname}{" "}
              </li>
              <li>Email:{userdetails.email}</li>
              <li>Phone:{userdetails.phone}</li>
            </ul>
          </div>
          <div className="  ">
            <p className="font-semibold   text-lg text-primary">
              {"School Proprietor"}
            </p>
            <ul className="font-light truncate">
              <li>
                Name:{propsdetails.firstname} {propsdetails.lastname}
              </li>
              <li>Email:{propsdetails.email}</li>
              <li>Phone:{propsdetails.phone}</li>
            </ul>
          </div>
        </div>
        {/* Certificates */}
        <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">
          <h1 className=" font-semibold text-lg text-primary ">Certificates</h1>

          {userschool.schooltype == "Private School" ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {certs?.map((item, key) => (
                <Certitem item={item} />
              ))}
            </div>
          ) : (
            <DoNotApply />
          )}
        </div>
        {/* Staffs and students */}
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">
            <p className="font-semibold text-lg text-primary">
              Number of Teaching staff
            </p>
            <ul className="font-bold">
              <li className=" flex flex-col justify-center items-center">
                {allstaffs.length}{" "}
                <p className="text-xs font-light">Genrated from report</p>
                {/* <FaEdit
                  onClick={() => {
                    seteditstaff((prevstate) => !prevstate);
                  }}
                  className="text-primary inline  ml-2"
                  size={20}
                /> */}
              </li>
              {editstaff && (
                <li>
                  <InputField
                    name="staffnoo"
                    title="Number of staffs"
                    placeholder="Enter number of staffs"
                    control={control}
                    rules={{
                      required: " is required",
                      pattern: {
                        value: numReg,
                        message: "Invalid number",
                      },
                    }}
                  />
                  <Button text="Save" onBtnClick={handleSubmit(onsavestaff)} />
                </li>
              )}
            </ul>
          </div>

          <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">
            <p className="font-semibold text-lg text-primary">
              Number of Student
            </p>
            <ul className=" font-bold  ">
              <li className=" flex flex-col justify-center items-center">
                {totalstudents}{" "}
                <p className="text-xs font-light">Genrated from report</p>
                {/* <FaEdit
                  onClick={() => {
                    seteditstud((prevstate) => !prevstate);
                  }}
                  className="text-primary inline  ml-2"
                  size={20}
                />{" "} */}
              </li>
              {editstud && (
                <li>
                  <InputField
                    name="studnoo"
                    title="Number of students"
                    placeholder="Enter number of students"
                    control={control}
                    rules={{
                      required: " is required",
                      pattern: {
                        value: numReg,
                        message: "Invalid number",
                      },
                    }}
                  />
                  <Button text="Save" onBtnClick={handleSubmit(onsavestud)} />
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">
          <h1 className=" font-semibold text-lg text-primary ">
            Link to School website/Social media
          </h1>

          <p className=" text-[15px] p-5">
            <a className=" text-blue-400  " href={userschool.link}>
              <FaExternalLinkAlt
                className="text-primary inline  mx-2"
                size={20}
              />
              Visit
            </a>{" "}
            <FaEdit
              onClick={() => {
                seteditlink((prevstate) => !prevstate);
              }}
              className="text-primary inline  ml-2"
              size={20}
            />
            {editlink && (
              <li>
                <InputField
                  name="link"
                  title="School website/Media"
                  placeholder="Enter url"
                  control={control}
                  rules={{
                    required: " is required",
                    pattern: {
                      value: urlregex,
                      message: "Invalid url",
                    },
                  }}
                />
                <Button text="Save" onBtnClick={handleSubmit(onsavelink)} />
              </li>
            )}
          </p>
        </div>

        <div className=" bg-customGray  flex justify-center flex-col items-center   text-sm rounded-b-md p-3 m-2">
          <h1 className=" font-semibold text-lg text-primary ">Payments</h1>
          {userschool.schooltype == "Private School" ? (
            <div className="bg-gray-100 p-2">
              {paymentItems.map((i) => (
                <PaymentListItem
                  isdash={true}
                  payment={schoolpaments}
                  item={i}
                />
              ))}
            </div>
          ) : (
            <DoNotApply />
          )}
        </div>
      </div>

      <div className="px-8"></div>
    </div>
  );
};

export { AccountDashbaord };
