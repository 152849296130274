import { useForm } from "react-hook-form";
import InputField from "../components/generic/Inputfield";
import { emailReg, numReg, passwordReg, textReg } from "../utils/regex";
import { logo } from "../constants/imports";
import Button from "../components/generic/Button";
import { Link, useNavigate } from "react-router-dom";
import { onSignup } from "../services/auth";
import { useState } from "react";
import { toast } from "react-toastify";

import Spinner from "../components/generic/Spinner";
import { SendVerificationlink } from "../utils/functions";

const Signup = () => {
  var navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    let formdata = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      school: "nill",
      phone: data.phone,
      password: data.password,
      coverphoto: "",
      verified: false,
    };

    setLoading(true);

    if (!(data.password == data.confpassword)) {
      toast.error("Passwords don't match");
      setLoading(false);

      return;
    }

    try {
      setLoading(true);

      let res = await onSignup(formdata);
      setLoading(false);
      toast(res ? res.message : " An error occured");

      if (res.success) {
        SendVerificationlink(data.email, data.firstname);

        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
    //    setLoading(false);
  };

  return (
    <div className="bg-primary flex justify-center  p-6 items-center">
      <Spinner loading={loading} />
      <div className="bg-white min-w-[30vw] rounded-lg p-2 flex justify-center  items-center flex-col ">
        <img className="mb-2  w-[130px] " src={logo} />

        <p className="text-[14px] mb-1">Create An Account</p>

        <div className="w-full px-6">
          <InputField
            name="firstname"
            title="First Name"
            placeholder="Enter Firstname"
            control={control}
            rules={{
              required: "Firstname is required",
              pattern: {
                value: textReg,
                message: "Invalid Firstname",
              },
            }}
          />
          <InputField
            name="lastname"
            title="Last Name"
            placeholder="Enter Lastname"
            control={control}
            rules={{
              required: "Lastname is required",
              pattern: {
                value: textReg,
                message: "Invalid Lastname",
              },
            }}
          />
          <InputField
            name="email"
            title="Email Address"
            placeholder="Enter Email Address"
            control={control}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />

          <InputField
            name="phone"
            title="Phone number"
            placeholder="Enter phone number"
            control={control}
            rules={{
              required: "Phone is required",
              pattern: {
                value: numReg,
                message: "Invalid Phone number",
              },
            }}
          />

          <InputField
            name="password"
            title="Password"
            placeholder="Enter password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />

          <InputField
            name="confpassword"
            title="Confirm Password"
            placeholder="Repeat password"
            control={control}
            rules={{
              required: "Repeat password is required",
            }}
            type={"password"}
          />
          <div className="w-full flex justify-between items-center ">
            {" "}
            <p className="text-[12px] text-center text-primary">
              <input type="checkbox" />I have read and agree to SchoolVerse's{" "}
              <Link className="font-semibold" to="/login">
                Privacy policy
              </Link>
            </p>{" "}
          </div>
          <Button text="Create account" onBtnClick={handleSubmit(onSubmit)} />
          <p className="text-primary text-[13px] w-full text-center">
            Already have an account?{" "}
            <Link className="font-semibold" to="/login">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export { Signup };
