import { useForm } from "react-hook-form";
import InputField from "../generic/Inputfield";
import { LocalNumberRegex, numReg, textReg } from "../../utils/regex";
import Button from "../generic/Button";
import { onAddFee, onEditFee } from "../../services/fees";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddFeeModal = (props) => {
  const { control, handleSubmit } = useForm();

  const submit = async (data) => {
    const postdata = {
      school: props.school,
      category: props.category,
      feevalue: data.amount,
      fee: data.feename,
    };

    console.log(data.amount);

    console.log(postdata);

    props.loading(true);
    try {
      var res = await onAddFee(postdata);
      if (res.message) {
        toast.success(res.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);

        props.showaddmodal(false);
      }

      props.loading(false);
    } catch (e) {
      console.log(e);
      props.loading(false);
    }
  };
  return (
    <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#00000073] justify-center items-center ">
      <div className="bg-white rounded w-[80vw] md:w-[30vw] p-10 ">
        <h2 className="text-primary">Add new fee</h2>
        <InputField
          name="feename"
          title="Fee name"
          rules={{
            required: "Title is required",
            pattern: {
              value: textReg,
              message: "Invalid title",
            },
          }}
          placeholder="Enter a title"
          control={control}
        />

        <InputField
          name="amount"
          title="Amount(₦)"
          placeholder={props.fee.ammount}
          control={control}
          rules={{
            required: "amount is required",
            pattern: {
              value: LocalNumberRegex,
              message: "Invalid amount",
            },
          }}
        />

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.showaddmodal(false);
            }}
          />
          <Button width={55} text="Add" onBtnClick={handleSubmit(submit)} />
        </div>
      </div>
    </div>
  );
};
export { AddFeeModal };
