import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { passwordReg, textReg } from "../../utils/regex";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";
import Spinner from "../generic/Spinner";
import { useState } from "react";
import { setGovconfig, setGovStaffconfig } from "../../services/user";
import { CiCircleInfo } from "react-icons/ci";

export default (props) => {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      if (props.portallock == "http://govstaff.schoolversedata.com") {
        var res = await setGovStaffconfig({
          config: {
            open: !props.lockstatus,
          },
        });
      } else if (props.portallock == "http://gov.schoolversedata.com") {
        var res1 = await setGovconfig({
          config: {
            open: !props.lockstatus,
          },
        });
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
      props.show(false)
      props.refresh()
    }
  };

  return (
    <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex bg-[#000000a9] justify-center items-center ">
      <Spinner loading={loading} />

      <div className="bg-white flex flex-col rounded h-[50vh] w-[30vw] justify-center   p-10 ">
       
      <CiCircleInfo size={40}  className="text-primary mb-4"/>
       
        <h2 className="text-gray-600 font-semibold capitalize">
          {" "}
          {props.lockstatus? "Lock" : "Unlock"} Registration Portal
        </h2>
       {props.lockstatus}  <span className="text-blue-400 text-sm font-light">
          {props.portallock}
        </span>
        <p className="text-primary text-sm">
          {" "}
          Registration would be   {props.lockstatus ? "Closed" : "Opened"} for this portal{" "}
        </p>

        <div className="w-full  flex  gap-3 justify-center items-end">
          <Button
            outlined={true}
            width={55}
            text="Cancel"
            onBtnClick={() => {
              props.show(false);
            }}
          />

          <Button width={55} text={`${props.lockstatus ?"Lock" : "Unlock"} `} onBtnClick={submit} />
        </div>
      </div>
    </div>
  );
};
