import { useLocation, useNavigate } from "react-router-dom";
import { sidebaritems } from "../../constants/data";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export default function (props){


const navigate=useNavigate()
const dispatcher=useDispatch()
const location =useLocation()
const authuser = useSelector((state) => state.authuser);
var userschool = useSelector((state) => state.userschool);

    return ( <div className="  absolute top-20 p-4 pr-8 right-5 z-[100000] bg-customBlack">
        {sidebaritems.map((item) => ( 
          <div
            onClick={() => {
              if (authuser.school != "nill" && !userschool.locked) {
                navigate(`${item.path}`);
                dispatcher({ type: "set-page-title", payload: item.name });
              }
props.show(false)
            }}
            className={`p-2 ${
              location.pathname === item.path && "bg-primary "
            } ${
              location.pathname == "accountdashboard" && "bg-primary "
            } my-4 rounded `}
          >
            <p className="inline text-[13px] cursor-pointer  font-semibold text-white">
              {item.icon} {item.name}
            </p>
          </div>
        ))}
      </div>)
}