import { useEffect, useState } from "react";
import { logolight } from "../../constants/imports";
import Button from "../generic/Button";
import { AdminLogin } from "./AdminLoginModal";
import { FaLock } from "react-icons/fa";
import LockStatModal from "./LockStatModal";
import {  onGetGovconfig,
  onGetGovstaffconfig,
  onGetGovStaffs,
} from "../../services/user";
import Spinner from "../generic/Spinner";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const AdminHero = (props) => {
  const [adminLogin, setAdminlogin] = useState(true);
  const [portallock, setportallock] = useState(true);
  const [showslock, setshowslock] = useState(false);
  const [govconfig, setgovconfig] = useState({});
  const [lockstatus, setlockstatus] = useState(false);
  const [govstaffconfig, setgovstaffconfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [govs, setgovs] = useState([]);
  const [govstaffs, setgovstaffs] = useState([]);
const dispatcher =useDispatch()

  const getAllGovStaffs = async () => {
    var res = await onGetGovStaffs();
    let emptgov = [];
    let emptgovstaff = [];

    for ( let user in res) {
      if (res[user].position==undefined) {
        emptgovstaff.push(res[user]);
        console.log(res[user],"bnbn")
      } 
      
      else {
        emptgov.push(res[user]);

      }
    }



    setgovstaffs(emptgovstaff)
     dispatcher({type:'set-govstaffs',payload:emptgovstaff})

    setgovs(emptgov)
    dispatcher({type:'set-govs',payload:emptgov})

  };
  const getConfigs = async () => {
    try {
      var res1 = await onGetGovconfig();
      setgovconfig(res1.config);

      var res2 = await onGetGovstaffconfig();
      setgovstaffconfig(res2.config);
    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigs();
    getAllGovStaffs();
  }, []);

  return (
    <div className=" p-4 rounded coverphotoholder ">
      {showslock && (
        <LockStatModal
          refresh={getConfigs}
          lockstatus={lockstatus}
          govstaffconfig={govstaffconfig}
          govconfig={govconfig}
          portallock={portallock}
          show={setshowslock}
        />
      )}
      <Spinner loading={loading} />

      {adminLogin && <AdminLogin showaddmodal={setAdminlogin} />}
      <img width={200} src={logolight} />
      <h1 className="font-bold text-xl text-white py-8 px-4">Admin Panel</h1>
      <div className="w-full flex  justify-end items-center ">
        <div className="flex justify-center flex-col p-1 bg-[#ffffff44] m-3 rounded items-center">
          <button
            onClick={() => {
              setlockstatus(govstaffconfig?.open);
              setshowslock(true);
              setportallock("http://govstaff.schoolversedata.com");
            }}
            className="bg-amber-500 font-semibold  text-white flex justify-center items-center p-1 rounded"
          >
            <FaLock className="text-white inline mr-1" />
            {govstaffconfig?.open ? "Lock" : "Unlock"}
          </button>
          <p className="text-white font-light"> Govstaff accounts</p>
          <p className="text-white  text-xl font-semibold">{govstaffs.length}</p>
          <Link to ="/admin/govstaffs" className=" cursor-pointer bg-blue-100 rounded text-xs p-1">View all</Link>
        </div>
        <div className=" flex justify-center flex-col rounded p-1 bg-[#ffffff44] m-3 items-center">
          <button
            onClick={() => {
              setlockstatus(govconfig?.open);
              setshowslock(true);
              setportallock("http://gov.schoolversedata.com");
            }}
            className="bg-amber-500 font-semibold  text-white flex justify-center items-center p-1 rounded"
          >
            <FaLock className="text-white inline mr-1" />
            {govconfig?.open ? "Lock" : "Unlock"}
          </button>
          <p className="text-white font-light ">Gov accounts</p>
          <p className="text-white text-xl font-semibold">{govs.length}</p>
          <Link to ="/admin/govs" className=" cursor-pointer bg-blue-100 rounded text-xs p-1">View all</Link>
        </div>
        <div className=" flex justify-center flex-col rounded p-1 bg-[#ffffff44] m-3 items-center">
          <p className="text-white font-light ">Registered accounts</p>
          <p className="text-white text-xl font-semibold">{props.totalusers}</p>
        </div>
        <div className=" flex justify-center flex-col rounded p-1 bg-[#ffffff44] m-3 items-center">
          <p className="text-white font-light ">Registered accounts</p>
          <p className="text-white text-xl font-semibold">{props.totalusers}</p>
        </div>
      </div>
    </div>
  );
};

export { AdminHero };
