import { useState } from "react";
import Spinner from "../../components/generic/Spinner";
import MapComponent from "../../components/ui/MapItem";
import { useDispatch, useSelector } from "react-redux";
import { useLocationHook } from "../../hooks/useLocationHook";

const Map =()=>{
    const [loading, setLoading] = useState(false);
var dispatcher =useDispatch()
var userschool = useSelector((state) => state.userschool);

var locationhook=useLocationHook()
    dispatcher({ type: "set-page-title", payload: "School map location" });


    return(<>
     <div className="bg-customGray h-full px-10 ">

     <Spinner loading={loading} />


     <MapComponent 
       userschool={userschool}
     setLoading={setLoading} Hook={locationhook} isViewonly={false}/>

     </div>
    </>)
}

export{Map}