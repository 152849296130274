import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReviewItem } from "../../components/Review/ReviewItem";
import { SchoolRating } from "../../components/Review/SchoolRating";
import Spinner from "../../components/generic/Spinner";
import { onGetSchoolReview } from "../../services/review";
import { onGetAllstaffs } from "../../services/user";
import { FaEye } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import { toast } from "react-toastify";
import { TableData } from "../../components/ui/Table";
import { onVerifyStaff } from "../../services/school";
import { FaMessage } from "react-icons/fa6";
import ComposeMsg from "./message/ComposeMsg";

export default () => {
  const school_ = useSelector((state) => state.userschool);
  const [loading, setLoading] = useState(false);
  const [rating, setrating] = useState(0);
  const [reviewItems, setReviewItems] = useState([]);
  const [schoolstaffs, setschoolstaffs] = useState([]);
  const HeaderItems = ["Photo", "Names", "Role", "Phone", "Status", "Action"];
  const [search, setsearch] = useState("");
  const navigate = useNavigate();
  const [selecteditem, setselecteditem] = useState({});
  const [showviewmodal, setshowviewmodal] = useState(false);
  const [showsendmsg, setshowsendmsg] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getallschoolstaffs = async () => {
    setLoading(true);
    var ress = await onGetAllstaffs();
    setLoading(false);

    var empt = [];

    for (let staff in ress) {
      if (ress[staff].schhool == school_.schoolname) {
        empt.push(ress[staff]);
      }
    }

    setschoolstaffs(empt);
  };

  useEffect(() => {
    if (!school_.schoolname) {
      navigate("/");
      return;
    } else {
    }

    getallschoolstaffs();
  }, []);

  return (
    <div className=" ">
      <Spinner loading={loading} />

      {showviewmodal && (
        <StaffViewModal
          refresh={getallschoolstaffs}
          setLoading={setLoading}
          selecteditem={selecteditem}
          show={setshowviewmodal}
        />
      )}
      {showsendmsg && (
        <SendMsgModal
          refresh={getallschoolstaffs}
          setLoading={setLoading}
          selecteditem={selecteditem}
          show={setshowsendmsg}
        />
      )}
      <div className="p-4 bg-white shadow  ">
        <p className="text-primary text-[20px] font-semibold">
          Staff of {school_.schoolname} ({schoolstaffs.length})
        </p>
        <small className="font-light">Manage school's staff</small>
      </div>

      <div className="bg-customGray w-full flex flex-col p-4 m-4">
        <div className=" p-3  bg-white">
          

          <div className="w-full overflow-x-scroll">
            <table className=" w-full bg-white ">
              <thead className="w-full bg-[#EAECF0]">
                <tr className="w-full">
                  {HeaderItems.map((item, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-3 md:px-6 py-1  md:py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {schoolstaffs.map((item, index) => (
                  <tr
                    className="bg-white border-b md:py-12 hover:bg-gray-50"
                    key={index}
                  >
                    <td>
                      <img
                        src={item.passport}
                        className=" border border-1 border-orange flex md:ml-4 justify-center rounded-full w-9"
                      />
                    </td>
                    <TableData item={`${item.firstname} ${item.lastname}`} />
                    <TableData item={item.role} />
                    <TableData item={item.phone} />
                    <TableData
                      item={item.verified ? "Verified" : "Not Verified"}
                    />

                    <td>
                      <div className="flex justify-start rounded items-center gap-2">
                        <FaEye
                          onClick={() => {
                            setselecteditem(item);
                            setshowviewmodal(true);
                          }}
                          size={20}
                          className="text-primary  hover:bg-slate-300 "
                        />
                        <FaMessage
                        onClick={() => {
                          setselecteditem(item);
                          setshowsendmsg(true);
                        }}
                          size={20}
                          className="text-primary   hover:bg-slate-300 "
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const StaffViewModal = ({ refresh, show, setLoading, selecteditem }) => {
  const verifyStaff = async (email) => {
    var postdata = {
      email: email,
      verified: true,
    };
    try {
      setLoading(true);
      var res = await onVerifyStaff(postdata);
      toast("Staff updated ");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      show(false);
      refresh();
    }
  };
  const DeleteStaff = async (email) => {
    var postdata = {
      email: email,
      schhool: "",
      verified: false,
    };
    try {
      setLoading(true);
      var res = await onVerifyStaff(postdata);
      toast("Staff updated ");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      show(false);
      refresh();
    }
  };

  return (
    <div className=" w-screen h-screen fixed top-0 left-0 bg-[#000000b5] flex justify-center items-center z-[3000] overflow-y-scroll overflow-x-hidden">
      <div className="w-[50vw] bg-white relative mt-4 flex flex-col gap-2 p-4 justify-center items-center">
        <MdClose
          onClick={() => {
            show(false);
          }}
          size={20}
          className="absolute top-5 right-5"
        />

        <p>Staff verification status</p>
        <p className="font-light">
          {selecteditem?.verified ? (
            <>
              <MdOutlineVerified className="text-primary inline " /> Verified
            </>
          ) : (
            <>
              <GoUnverified className="inline text-rose-400" />
              Not Verified
            </>
          )}
        </p>
        <img
                        src={selecteditem.passport}
                        className=" border border-1 border-orange flex md:ml-4 justify-center rounded-full w-[200px]"
                      />
        <div className="flex w-full justify-end  gap-4">
          <button
            onClick={() => {
              DeleteStaff(selecteditem.email);
            }}
            className="p-1 text-sm rounded-md  cursor-pointer bg-amber-500 text-white"
          >
            Delete staff
          </button>
          {!selecteditem.verified && (
            <button
              onClick={() => {
                verifyStaff(selecteditem.email);
              }}
              className="p-1 text-sm rounded-md  cursor-pointer bg-amber-500 text-white"
            >
              Verify staff
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
const SendMsgModal = ({ refresh, show, setLoading, selecteditem }) => {
  

  return (
    <div className=" w-screen h-screen fixed top-0 left-0 bg-[#000000b5] flex justify-center items-center z-[3000] overflow-y-scroll overflow-x-hidden">
      <div className="w-[50vw] bg-white relative mt-4 flex flex-col gap-2 p-4 justify-center items-center">
        <MdClose
          onClick={() => {
            show(false);
          }}
          size={20}
          className="absolute top-5 right-5"
        />
    <p className="">Send message to {selecteditem.firstname} {selecteditem.lastname}</p>
      
    <ComposeMsg show={show} message={{from:selecteditem.email}} />


      </div>
    </div>
  );
};



