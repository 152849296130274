import { sealed } from "../../constants/imports"

export default ()=>
    
    {

return(<div  className="h-screen flex justify-center items-center fixed  left-0 top-[10vh] w-full  z-[10000] bg-[#ffffffb7]">

<img src={sealed}/>

</div>)

}

