import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
 import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
 import { ReviewItem } from "../../components/Review/ReviewItem";
  import { SchoolRating } from "../../components/Review/SchoolRating";
import Spinner from "../../components/generic/Spinner";
import { onGetSchoolReview } from "../../services/review";
const Review = () => {
  const school_ = useSelector((state) => state.userschool);
  const [loading, setLoading] = useState(false);
  const [rating,setrating]=useState(0)
  const [reviewItems, setReviewItems] = useState([]);
  const HeaderItems = ["Review item title", "Score", "Status", "Last updated"];
  const getSelectedschoolReview = async () => {
    setLoading(true)
    var formdata = { schoolname: school_.schoolname };
    var res = await onGetSchoolReview(formdata);
    setLoading(false)
    console.log(res.review_items, "....///....");
    setReviewItems(res.review_items);
  };

  useEffect(() => {
    getSelectedschoolReview();
  }, []);

  const navigate = useNavigate();

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  
  useEffect(() => {

    if (school_.schoolname == undefined) {
      navigate("/");
      return;
    }
  }, []);

  useEffect(()=>{


    
let sum=0
let percent=0
    reviewItems.map((k)=>{
sum=sum+k.score

    }  )

    percent=Math.ceil((sum/80)*100)
    setrating(percent)
  },[reviewItems])


  

  return (
    <div className="">
      <Spinner loading={loading} />
      <div className="p-4 bg-white shadow  ">
        <p className="text-primary text-[20px] font-semibold">
         
          Review & Rating for {school_.schoolname}
        </p>

        <small className="font-light">View review details of your school by Gov. Staff</small>

      </div>

      <div className="bg-customGray flex flex-col p-4 m-4">

     

        <div className=" p-3  bg-white">

        <SchoolRating percent={rating} num={Math.ceil((rating/100)*5)}/>

          <div className="p-4 m-2 flex justify-between  bg-[#999999]">
            {HeaderItems.map((item, key) => (  <p className={`font-bold ${key==0? "w-[60%]":""} capitalize  text-white`}>{item}</p>  ))}
          </div>

          {reviewItems.map((item, key) => (
            <ReviewItem key_={key} item={item} />
          ))}
        </div>

      
      </div>
    </div>
  );
};
export { Review };
