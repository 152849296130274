import { MdClose } from "react-icons/md"
import { RegisterSchool } from "../../components/Dashboard/RegisterScgool"

export default ({show})=>{


  return(
  <div className=" w-screen h-screen fixed top-0 left-0 bg-[#000000b5] flex justify-center items-start overflow-y-scroll overflow-x-hidden">
    <div className="min-w-[60%] md:w-[60%] relative ">
      <MdClose onClick={()=>{

        show (false)
      }} size={20} className="absolute top-5 right-5" />
    <RegisterSchool show={show} isedit={true}/>
    </div>

  </div>)
}

