import { CiPickerEmpty } from "react-icons/ci"
import { FaExclamation, FaTrash } from "react-icons/fa"

const FeesItem=(props )=>(<div className="p-2 bg-customGray my-1 md:my-2  flex justify-between w-full">

    
    <p className="font-semibold text-sm truncate text-primary  whitespace-nowrap max-w-[70%]">
{props.item.title}
    </p>

    <p className="text-black inline text-sm font-semibold mr-4">
        {props.item.ammount==''?<FaExclamation className='text-red inline' />:''}
     {props.item.ammount}    
     <CiPickerEmpty   onClick={()=>{  props.setselected(props.item)
         props.showeditmodal(true)   }} className='text-primary inline mx-2 active:bg-black p-1' size={20} /> 
         <FaTrash
           onClick={()=>{  props.setselected(props.item)
            props.showdelete(true)   }} className='text-primary  mx-2 inline active:bg-black p-1' size={20}
         />
    </p>
</div>)

export{FeesItem}