import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { TableData } from "../components/ui/Table";
import { logo } from "../constants/imports";
import Spinner from "../components/generic/Spinner";
import { IoSearch } from "react-icons/io5";
import { onGetAllSchool } from "../services/school";

export default () => {
  const [postdata, setpostdata] = useState({});
  const [loading, setLoading] = useState();
  const HeaderItems = ["No.", "Logo", "School name", "Address"];

  const navigate = useNavigate();
  const dispather = useDispatch();
  const { control, handleSubmit } = useForm();
  const [schools, setschools] = useState([]);

  const [renderedschools, setrenderedschools] =useState([])
  const [search, setsearch] = useState("");

  const getallschools = async () => {
    setLoading(true);

    try {
      var res = await onGetAllSchool();

      let arr = [];
      for (let schoolname in res) {
        arr.push(res[schoolname]);
      }

      setschools(arr);
      setLoading(false);

    } catch (e) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getallschools()
  },[])


  useEffect(()=>{
setrenderedschools(schools)

  },[schools])

  useEffect(()=>{

setrenderedschools(()=>schools.filter((ik)=>ik.schoolname.toLowerCase().includes(search.toLowerCase())))

  },[search])
  return (
    <div className="w-full flex md:p-4 justify-center flex-col  items-center  ">
      <div className="  relative w-[300px] flex justify-center self-start items-center mb-2 ">
        <IoSearch className="absolute left-1   text-xl text-zinc-400" />
        <input
          type={"text"}
          placeholder={"Search by school name"}
          onChange={(e) => setsearch(e.target.value)}
          className={`pl-8 block bg-white text-xs py-2 px-4 rounded-md w-full shadow-xs border border-gray-300}`}
        />
      </div>

      <Spinner loading={loading} />
      <div className="w-full overflow-x-scroll">
        <table className=" w-full bg-white ">
          <thead className="w-full bg-[#EAECF0]">
            <tr className="w-full">
              {HeaderItems.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-3 md:px-6 py-1  md:py-3 uppercase text-left text-xs font-semibold whitespace-nowrap"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderedschools.map((item, index) => (
              <tr
                className="bg-white border-b md:py-12 hover:bg-gray-50"
                key={index}
              >
                <TableData item={index} />

                <td>
                  <img
                 
                    src={item.schoollogo}
                    className="   border-2 border-orange flex md:ml-4 justify-center rounded-full w-9 h-9"
                  />
                </td>
                <TableData item={`${item.schoolname} `} />
                <TableData item={item.address} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
