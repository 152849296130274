import { FaEye } from "react-icons/fa";
import Button from "../generic/Button";
import { ViewPaymentModal } from "./ViewPaymentDetails";
import { set } from "react-hook-form";
import { useState } from "react";
import Spinner from "../generic/Spinner";

const PaymentListItem = (props) => {
  const [viewpayment, setviewpayment] = useState(false);

  return (
    <div className="flex justify-between  gap-4 items-center text-sm bg-white rounded px-2 shadow p-4 w-full">
      {viewpayment && (
        <ViewPaymentModal
          refresh={props.refresh}
          payment={props.payment}
          setshow={setviewpayment}
          item={props.item}
        />
      )}

      <p className={` ${props.isdash ? "" : "truncate w-[20%]"} text-gray-600`}>
        {" "}
        {props.item.title}
      </p>
      <p
        className={`p-1  text-center text-sm ${
          props.payment ? "bg-faint" : "bg-rose-200"
        } rounded  text-sm`}
      >
        {props.payment ? "Paid" : "Not paid"}
      </p>
      {!props.isdash && (
        <>
          {" "}
          <p>{props.item.dueDate}</p>
          <p>{props.item.nextDueDate}</p>
        </>
      )}
      {!props.isdash && (
        <Button
          width={50}
          text={
            <>
              <FaEye />
            </>
          }
          onBtnClick={() => {
            setviewpayment(true);
          }}
        />
      )}
    </div>
  );
};
export { PaymentListItem };
