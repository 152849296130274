import { useDispatch } from "react-redux"

const Rating=()=>{
    const dispatcher=useDispatch()
    dispatcher({type:'set-page-title',payload:'Rating'})

    return (<div className="flex  h-full justify-center items-center ">


<p className="text-3xl font-bold text-primary">View admin rating here </p>
    </div>)
}

export{Rating}