import { useSelector } from "react-redux"

const SelectedHero =()=>{

var props=useSelector((state)=>state.selectedSchool)
var categories = props.schoolcategories?.split(",");


    return (<div className=" p-4 rounded coverphotoholder  flex ">
    <img  className='rounded-full' width={200} src={props.schoollogo} />
  <h1 className="font-bold text-white py-8 px-4">
      {props.schoolname}
  </h1>
  <div className="w-full flex  justify-end items-center ">
      <div className="flex justify-center flex-col p-1 bg-[#ffffff44] m-3 rounded items-center">
          <p className="text-white font-light">Number of Staffs</p>
          <p className="text-white  text-xl font-semibold">{props.staffs}</p>
      </div>
      <div className=" flex justify-center flex-col rounded p-1 bg-[#ffffff44] m-3 items-center">
          <p className="text-white font-light ">Number of Students</p>
          <p className="text-white text-xl font-semibold">{props.students}</p>
      </div>
      
  </div>

  <div className="p-4 text-white font-light bg-primary justify-center items-center ">
      <p> Year founded:
        {props.yearfounded} </p> 
        <br/>
        <p>School categories :</p><br/>
        {categories?.map((item) => (
          <p className="inline p-2 rounded mx-2 bg-white font-light  text-black ">
            {item}
          </p> 
        ))}
      </div>
  </div>)
}
export{SelectedHero}