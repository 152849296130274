import { useDispatch, useSelector } from "react-redux"
 import { useEffect, useState } from "react"
import { onGetAllcertificates } from "../../services/certificates"
import Spinner from "../../components/generic/Spinner"
 
 import { CertificateItem } from "./Certificates/CertificateItem"
import { ConfirmDelete } from "./Certificates/DeleteConfirm"
import { ConfirmApproval } from "./Certificates/ConfirmApproval"
import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from "react-router-dom"



const headeritems = ['Name', 'Status', 'Actions']

const Certificates = () => {
    const dispatcher = useDispatch()
    var [certificates, setcerts]=useState([])
    const [loading, setLoading] = useState(false);
    const [selectedcert, setselectedcert]=useState()
    var selectedSchool =useSelector(state=>state.selectedSchool)
    const school_=useSelector((state)=>state.selectedSchool)

    dispatcher({ type: 'set-page-title', payload: 'Certificates' })
    var [showaddmodal, setshowaddmodal] = useState(false);
    var [showview, setshowview] = useState(false);
    var [showdeletemodal, setshowdeletemodal]=useState(false)
    var [showverify, setshowverify]=useState(false)

const navigate=useNavigate()
   



const init= async ()=>{
const postdata={school:selectedSchool.schoolname}
 
setLoading(true)
var res=await onGetAllcertificates(postdata)
setLoading(false)
if (res.certicates){

    setcerts(res.certicates)
    console.log(res.certicates)
}


}


useEffect(()=>{
console.log(school_.schoolname)
   
  if(school_.schoolname==undefined){
    navigate('/admin')
   return
   }
    init()

},[])




    return (<div className="h-full bg-customGray p-10 ">
<Spinner loading={loading} />
 {showdeletemodal && <ConfirmDelete   loading={setLoading} refresh={init} selectedcert={selectedcert} showaddmodal={setshowdeletemodal} />}
 {showverify && <ConfirmApproval   loading={setLoading} refresh={init} selectedcert={selectedcert} showaddmodal={setshowverify} />}
 <div className="flex   items-center">

<FaArrowLeft className="m-3 mb-3 text-primary" size={30} onClick={()=>navigate(-1)}/><h1>{school_.schoolname} Certificates</h1>
</div>        <div className="bg-white p-3">
            {/* Table Header */}

            <div className="bg-customGray my-2 flex w-full px-2 py-3 justify-between">{headeritems.map((item) => (<p className="uppercase text-xs  text-black">{item}</p>))}</div>
{certificates.map((item, key)=>(<CertificateItem  setshowdel={setshowdeletemodal} setshowview ={setshowview} setshowverify={setshowverify} setshowaddmodal={setshowaddmodal} key_={key} setselectedcert={setselectedcert} item={item} />))}
        </div>



    </div>)
}

export { Certificates }