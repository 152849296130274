import { dummy_image } from "../../constants/imports";

 function PhotoUpload (props){
   const onchange= (event)=>{
    const files = event.target.files;
    const file =(files[0]);
    props.hook.setfile(file)

    var img= document.getElementById("uploadimg");

    var reader = new FileReader();
    reader.onloadend = function() {
         img.src = reader.result;
    }
    reader.readAsDataURL(file);
    


   }

    const onclick=()=>{


        var input= document.getElementById('imageFile')
        input. click()
    }

    return(<div className=" p-4  flex justify-center items-center flex-col" > 
      <input onChange ={(e)=>onchange(e)}
      className='hidden' id='imageFile' type="file"/>   
      <img id='uploadimg' onClick ={onclick} className="w-[75px] opacity-50 bg-primary rounded-[50%]  p-3 " src={dummy_image} />
    <p onClick ={onclick} className="text-primary cursor-pointer text-sm font-bold mt-2">Upload Photo</p>
    </div>)
}
export{PhotoUpload}