import { CiInboxIn } from "react-icons/ci";
import { CiInboxOut } from "react-icons/ci";


export default ({item, click}) => {


  return <div 
  onClick={()=>{

    click()
    
      }} className="w-full flex items-center py-3 px-4 bg-white mt-2 border-gray-300 rounded">
   {item.issent ? <CiInboxOut  size={30} className=" inline text-primary font-light" />:
    <CiInboxIn  size={30} className=" inline text-primary font-light" />
}

<div  className="flex flex-col">
<p className=" text-xs font-medium mx-4 ">
              From {item.user ? item.user : item.from}{" "}
              {item.senderType} 
              
              {/* to {item.to} */}
            </p>
<p className="text-gray-500 font-light mx-4 ">
  
    
   {item.title}</p>
   <p className="text-[10px] font-light mx-4 ">{item.time}</p>
</div>


  </div>;
};
