import { FaCheck, FaCheckCircle } from "react-icons/fa"
import { logo, logolight } from "../constants/imports"
import Button from "../components/generic/Button"
import { useNavigate, useParams } from "react-router-dom"
import { onChangeuserPass, onVerifyuser } from "../services/user"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import InputField from "../components/generic/Inputfield"
import { useForm } from "react-hook-form"

const ResetPass =()=>{
var navigate=useNavigate()
const [message, setmessge]=useState()
const {control, handleSubmit} =useForm()
var {userId} =useParams()

const reset= async(data)=>{




    if(data.confpassword != data.password){


        toast.error("Passwords dont match")
        return
    }
var postdata={
    email:userId,
    newpassword:data.password
}
    var res =await onChangeuserPass(postdata)
    toast.success(res.message)
    setmessge(res.message)
    if(res.success){

navigate('/login')
    }
}

useEffect(()=>{


    
},[])
    return(<div className="bg-primary flex justify-center items-center h-[100vh] w-full ">


        <div className="bg-white rounded-lg  w-[80vw]     flex justify-center flex-col  items-center" >
            
            <img  className="flex justify-center mb-4 w-[130px]  items-center" src={logo} />
             <p>{userId}</p>
            <h1 className="text-lg font-light mb-4"> 
            {message}</h1>

            
<InputField
                name="password"
                title="Password"
                placeholder="Enter password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                type={"password"}
              />
              
         <InputField
                name="confpassword"
                title="Confirm Password"
                placeholder="Repeat password"
                control={control}
                rules={{
                  required: "Repeat password is required",
                }}
                type={"password"}
              />
            
            <Button  width={'auto'} text="Reset" onBtnClick={ handleSubmit(reset)} />
            </div>
           


    </div>)
}


export {ResetPass}