import { BsCardImage } from "react-icons/bs"
import { FaLocationDot } from "react-icons/fa6"
import { MdCancel } from "react-icons/md"

const Addreespick=(props)=>{


    return( <div className="my-4">
    {props. title && (
      <div className="flex items-center justify-between">
        <p className="text-xs mb-2">{props.title}</p>
      </div>
     
    )}
    

 <div className="bg-gray-50 rounded flex items-center w-full border justify-between" >
<div   onClick={()=>{ props. onclick()}} className="bg-black rounded w-[60%] flex justify-center items-center px-1 py-2 m-1 text-white font-light text-xs  cursor-pointer">

<FaLocationDot className=" mr-2"/>Enter address
</div>  
<p className="text-xs font-light jutify-self-start cursor-pointer ml-3  truncate "> {props.cord}</p>
 
 


    </div>
    </div>)
}


export{Addreespick}