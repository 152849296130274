import React, { FC } from "react";
 
const TableData= ({ item, ismain,color }) => {
  return (
    <td className={`px-6 py-3 ${ismain?"w-[60%]":""} text-xs ${color ? color :  "text-black"}`}>
      {item}
    </td>
  );
};
export { TableData };
