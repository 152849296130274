import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeCoverPhoto } from "../../components/Dashboard/ChangeCoverModal";
import Spinner from "../../components/generic/Spinner";
import Button from "../../components/generic/Button";
import { SetMapLocation } from "../../components/Dashboard/SetMapLocation";
import { useLocationHook } from "../../hooks/useLocationHook";
import EditProprietor from "../../components/Dashboard/EditProprietor";
import { useEffect } from "react";
import { onGetProprietor, onGetUser } from "../../services/user";
import { toast } from "react-toastify";
import EditSchoolModal from "./EditSchoolModal";
import DeleteSchoolModal from "./DeleteSchoolModal";
import { MdDelete } from "react-icons/md";
import EditUserDetails from "../../components/Dashboard/EditUserDetails";

const Settings = () => {
  const dispatcher = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showaddmodal, setshowmodal] = useState(false);
  var userschool = useSelector((state) => state.userschool);
  const [loactionpicker, setlocationpicker] = useState(false);
  var userschool = useSelector((state) => state.userschool);
  var locatioposhook = useLocationHook();
  const [propsdetails, setpropsdetails] = useState({});
  const [showeditprop, setshoweditprop] = useState(false);
  const [userdetails, setuserdetails] = useState({});
  const [showedituser, setshowedituser] = useState(false);
  const getUserdetails=async()=>{
    let postdata = { email: userschool.user };

    setLoading(true);

    try {
      setLoading(true);
      var response = await onGetUser(postdata);
      setLoading(false);
      setuserdetails(response);
    } catch (e) {
      setLoading(false);
  
      console.log(e);
      toast.error(e); 
    }
    setLoading(false);
  
  }
  const [showeditschool, setshoweditschool] = useState(false);
  dispatcher({ type: "set-page-title", payload: "Settings" });

  const onGetPropDet = async () => {
    let postdata = { schoolname: userschool.schoolname };
    try {
      setLoading(true);
      var response = await onGetProprietor(postdata);
      setLoading(false);
      setpropsdetails(response.details);
    } catch (e) {
      setLoading(false);

      console.log(e);
      toast.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
  const init= async()=>{
   await onGetPropDet();
  await  getUserdetails()
  }
  init()
  }, []);
  return (
    <div className="w-full">
             <Spinner loading={loading} />

      {showaddmodal && (
        <ChangeCoverPhoto loading={setLoading} showaddmodal={setshowmodal} />
      )}
      {loactionpicker && (
        <SetMapLocation Hook={locatioposhook} cancel={setlocationpicker} />
      )}
      {showeditschool && <EditSchoolModal show={setshoweditschool} />}
       <div className="m-4 bg-white rounded px-10 flex justify-center items-center ">
       <Spinner loading={loading} />
        {showeditprop && (
          <EditProprietor det={propsdetails} show={setshoweditprop} />
        )}
      
        {showedituser && (
          <EditUserDetails det={userdetails} show={setshowedituser} />
        )}

        <div className=" flex flex-col  justify-center items-center md:w-[50vw]">
          <Button
            outlined
            text="Change cover photo"
            onBtnClick={() => {
              setshowmodal(true);
            }}
          />
          <Button
            outlined
            text="Edit Proprietor's details"
            onBtnClick={() => {
              setshoweditprop(true);
            }}
          />
          <Button
            outlined
            text={`Edit ${userschool.creatorrole} details`}
            onBtnClick={() => {
              setshowedituser(true);
            }}
          />
          <Button
            outlined
            text="
            Edit School details"
            onBtnClick={() => {
              setshoweditschool(true);
            }}
          />
        
        </div>

        {/* <Button text="Set school location" onBtnClick={() => { setlocationpicker(true)}} /> */}
      </div>
    </div>
  );
};

export { Settings };
