import { useState } from "react";
import { usePickerhook } from "../../../hooks/usePickerhook";
import { ListPicker } from "../../ui/ListPicker";
import Button from "../../generic/Button";
import { FaEdit } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { onDeleteClass, onUpdateClass } from "../../../services/school";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { removeNonLetters } from "../../../utils/functions";
import { MdDelete } from "react-icons/md";
import DeleteClassModal from "./DeleteClassModal";

export default ({ item, staffs, key_, setLoading, refresh }) => {
  var [iseditable, setiseditable] = useState(false);
  const Userschool = useSelector((state) => state.userschool);
  const [showdelete, setshowdelete] = useState(false);

  const NumberBox = ({ title, id, value }) => {
    var [inpvalue, setinpvalue] = useState(value);

    const onchange = (e) => {
      setinpvalue(e);
      console.log(e);
    };

    return (
      <div className=" flex justify-between items-center m-2 shadow">
        <p className="text-xs m-1  text-gray-500 ">{title}</p>
        <input
          id={`${key_}${id}`}
          onChange={(val) => {
            onchange(val.target.value);
          }}
          disabled={true}
          value={inpvalue}
          className="w-[50px] inline h-[50px] bg-gray-100 text-[#215C88] font-semibold  p-2 singleDigit rounded  single-digit-input custom-outline "
          type="number"
        />
      </div>
    );
  };
  const assignedteacherhook = usePickerhook();

  return (
    <div className="flex flex-col justify-end items-end  bg-[#f0f0f0] rounded my-2 shadow px-2">
      {showdelete && (
        <DeleteClassModal
          show={setshowdelete}
          refresh={refresh}
          setLoading={setLoading}
          item={item}
        />
      )}
      <div className="flex gap-2">
        <FaEdit
          className="text-[#F8A81C] my-1"
          onClick={() => {
            setiseditable((prev) => !prev);
          }}
          size={20}
        />
      </div>
      <div className="w-full flex flex-col md:flex-row  justify-between p-2 ">
        <p className="font-bold   ">{item.name}</p>

        <div>
          {iseditable ? (
            <ListPicker
              default={
                item.assignedTeacher
                  ? {
                      code: removeNonLetters(item.assignedTeacher),
                      name: item.assignedTeacher,
                    }
                  : { code: "NoTAssigned", name: "Not Assigned" }
              }
              title="Assigned Teacher"
              list={staffs.map((i) => {
                return {
                  name: `${i.firstname} ${i.lastname}`,
                  code: removeNonLetters(i.email),
                };
              })}
              hook={assignedteacherhook}
            />
          ) : (
            <div className="relative my-4">
              <p className="text-xs mb-2 ">Assigned Class Teacher</p>
              <p className=" mb-2 text-sm">
                {" "}
                <FaUser className="inline text-[#F8A81C] " />
                {item.assignedTeacher ? item.assignedTeacher : "Not assigned"}
              </p>
            </div>
          )}
          <p className="text-xs font-semibold  ">
            Total students: {parseInt(item.male) + parseInt(item.female)}
          </p>
        </div>

        <div className=" ">
          <p className="text-xs">Gender</p>
          <NumberBox id={"maleCount"} title="Male" value={item.male} />
          <NumberBox id="femaleCount" title="FeMale" value={item.female} />
        </div>
        <div className=" ">
          <p className="text-xs">Age Range</p>
          <NumberBox id="minage" title="Min" value={item.minage} />
          <NumberBox id="maxage" title="Max" value={item.maxage} />
        </div>
      </div>
      {iseditable && (
        <Button
          outlined
          width={55}
          text="Save"
          onBtnClick={async () => {
            var maxage = document.getElementById(`${key_}${"maxage"}`).value;
            var minage = document.getElementById(`${key_}${"minage"}`).value;
            var femaleCount = document.getElementById(   `${key_}${"femaleCount"}`  ).value;
            var maleCount = document.getElementById(     `${key_}${"maleCount"}`    ).value;
            if (!assignedteacherhook.pickedRole) {
              toast.error("Assigned invalid teacher");
              return;
            }
            let postdata = {
              schoolname: Userschool.schoolname,
              classname: item.code,
              data: {
                name: item.name,
                code: item.code,
                assignedTeacher: assignedteacherhook.pickedRole.name,
                male: maleCount,
                female: femaleCount,
                minage: minage,
                maxage: maxage,
              },
            };

            console.log(postdata);

            setLoading(true);
            var res = await onUpdateClass(postdata);

            setLoading(false);
            if (res.success) {
              toast.success(res.message);

              await refresh();
            }
          }}
        />
      )}

      <p
        className="text-[#f82b1c] cursor-pointer text-xs my-1"
        onClick={() => {
          setshowdelete(true);
        }}
      >
        {" "}
        <MdDelete className="text-[#f82b1c]  inline   my-1" /> Delete class
      </p>
    </div>
  );
};
