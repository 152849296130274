import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminHero } from "../../components/Admin/AdminHero";
import { SchoolItem } from "../../components/Admin/SchoolItem";
import Button from "../../components/generic/Button";
import { onExportAlldata, onGetAllSchool } from "../../services/school";
import { onGetAllusers } from "../../services/user";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/generic/Spinner";
import { MdDelete } from "react-icons/md";

const Admin = () => {
  const [schools, setschools] = useState([]);
  const [users, setusers] = useState([]);
  const school_ = useSelector((state) => state.selectedSchool);
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [totalschool, settotalschools] = useState();
  const [totalusers, settotalusers] = useState();
  const getallschools = async () => {
    var res = await onGetAllSchool();
    //console.log(res,"response")

    settotalschools(Object.keys(res).length);
    var schoolsarray = [];
    for (let item in res) {
      schoolsarray.push(res[item]);
    }

    setschools(schoolsarray);
  };

  const getallusers = async () => {
    var res = await onGetAllusers();

    settotalusers(Object.keys(res).length);
    var usersarray = [];
    for (let item in res) {
      usersarray.push(res[item]);
    }

    setusers(usersarray);
  };

  const dispatcher = useDispatch();

  dispatcher({ type: "set-page-title", payload: "Admin panel" });

  const onExport = async () => {
    setLoading(true);
    let postData = {
      data: schools,
      school: "all",
    };
    var res = await onExportAlldata(postData);
    setLoading(false);
  };
  useEffect(() => {
    getallschools();
    getallusers();
  }, []);
  return (
    <div className="">
      <Spinner loading={loading} />
      <AdminHero totalusers={totalusers} total={totalschool} />

      <div className="bg-customGray flex flex-col p-4 m-4">
        <div className="  grid grid-cols-2 gap-3 place-self-end self-end ">
          <Button
            text="Export data"
            onBtnClick={() => {
              onExport();
            }}
          />

          <Button
            outlined
            text="Logout"
            onBtnClick={() => {
              setLoading(true);
              setTimeout(() => {
                localStorage.clear();
                window.location.reload();
                setLoading();
              }, 1500);
            }}
          />
        </div>
        <div className="grid grid-cols-3 px-6 gap-6">
          {schools.map((item) => (
            <SchoolItem item={item} />
          ))}

      
        </div>
      </div>
    </div>
  );
};
export { Admin };
