import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onGetAllcertificates } from "../../services/certificates";
import Spinner from "../generic/Spinner";

import { CertificateItem } from "./Certificates/CertificateItem";
import { ConfirmDelete } from "./Certificates/DeleteConfirm";
import { ConfirmApproval } from "./Certificates/ConfirmApproval";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import StaffItem from "../ui/StaffItem";
import { onGetGovStaffs } from "../../services/user";

const headeritems = ["Name", "Status", "Actions"];

export default () => {
  const dispatcher = useDispatch();
  var [certificates, setcerts] = useState([]);
  const [Govstaffs, setgovstaffs] = useState([]);
  const [Govs, setgovs] = useState([]);

   const [rendered, setrendered] = useState(Govs);
  const [loading, setLoading] = useState(false);
  const [selectedcert, setselectedcert] = useState();
  var selectedSchool = useSelector((state) => state.selectedSchool);
  const school_ = useSelector((state) => state.selectedSchool);
  const deleteStaff = (item) => {
    setrendered(Govstaffs.filter((k) => k != item));
  };
  dispatcher({ type: "set-page-title", payload: "Gov. Staffs" });
  var [showaddmodal, setshowaddmodal] = useState(false);
  var [showview, setshowview] = useState(false);
  var [showdeletemodal, setshowdeletemodal] = useState(false);
  var [showverify, setshowverify] = useState(false);
   const navigate = useNavigate();


  const getAllGovStaffs = async () => {
    var res = await onGetGovStaffs();
    let emptgov = [];
    let emptgovstaff = [];

    for ( let user in res) {
      if (res[user].position==undefined) {
        emptgovstaff.push(res[user]);
        console.log(res[user],"bnbn")
      } 
      
      else {
        emptgov.push(res[user]);

      }
    }



    // setgovstaffs(emptgovstaff)
    // dispatcher({type:'set-govstaffs',payload:emptgovstaff})

    setgovs(emptgov)
    // dispatcher({type:'set-govs',payload:emptgov})

  };
  const init = async () => {
    const postdata = { school: selectedSchool.schoolname };

    setLoading(true);
    var res = await onGetAllcertificates(postdata);
    setLoading(false);
    if (res.certicates) {
      setcerts(res.certicates);
      console.log(res.certicates);
    }
  };

  useEffect(() => {
    if (Govstaffs.lenght == 0) {
      navigate("/admin");
      return;
    }
    init();
    getAllGovStaffs()
  }, []);

  return (
    <div className="h-full bg-customGray p-10 ">
      <Spinner loading={loading} />

      <div className="flex   items-center">
        <FaArrowLeft
          className="m-3 mb-3 text-primary"
          size={30}
          onClick={() => navigate(-1)}
        />
        <h1>
          All Gov. staff registered on
          <span className="text-blue-400 text-sm">
             http://gov.schoolversedata.com
          </span>
        </h1>
      </div>

      <div className="bg-white p-3">
        {/* Table Header */}

        <div className="bg-customGray my-2 flex w-full px-2 py-3 justify-between">
          <p className="font-bold text-lg"> Total {Govs.length}</p>
        </div>

        <div className="grid grid-cols-4 gap-4">
          {Govs.map((item) => (
            <StaffItem refresh={getAllGovStaffs} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
