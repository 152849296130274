import { useForm } from "react-hook-form";
import { LGAs, States } from "../../constants/data";
import { useLocationHook } from "../../hooks/useLocationHook";
import { usePickerhook } from "../../hooks/usePickerhook";
import { addressRegex, textReg } from "../../utils/regex";
import Button from "../generic/Button";
import { ListPicker } from "../ui/ListPicker";
import MapComponent from "../ui/MapItem";
import InputField from "../generic/Inputfield";
import { toast } from "react-toastify";

const LocationModal = (props) => {


  const statepickerhook =usePickerhook()
  const lgapickerhook =usePickerhook()
  const { control, handleSubmit } = useForm();

const onSubmit=(data) =>{

if(!lgapickerhook.pickedRole){

  toast.error('Select an LGA')
  return
}

const completeaddress= `${data.addressline} ${data.city}  ${lgapickerhook.pickedRole.name} ${statepickerhook.pickedRole.name} State`
console.log(completeaddress)
props.setAddress(completeaddress)
props.cancel(false)

}
  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-[#000000a7] w-[100vw] h-[100vh]">
      <div className="bg-white min-w-[30vw] rounded px-10 py-4">
        {/* <MapComponent
          isviewonly={false}
          cancel={props.cancel}
          Hook={props.Hook}
        /> */}
        <ListPicker title='Select state' default={{name:"Osun",code:"osun" }} list={States.map((i)=>{

return{ name:i, code:i}
        })} hook={statepickerhook}/>
        <ListPicker title='Select LGA'   list={LGAs.map((i)=>{
          return{ name:i, code:i}
        })} hook={lgapickerhook}/>
        <InputField
            name="city"
            title="City"
            placeholder="City"
            control={control}
            rules={{
              required: "City is required",
              pattern: {
                value: textReg,
                message: "Invalid city",
              },
            }}
          /> 

<InputField
            name="addressline"
            title="Address Line"
            placeholder="Address Line"
            control={control}
            rules={{
              required: "Address line is required",
              pattern: {
                value: addressRegex,
                message: "Invalid address",
              },
            }}
          /> 


<div className='w-full  flex  gap-3 justify-end'> 

     
       <Button  width={40} outlined text="Cancel" onBtnClick={()=>{   props.cancel(false)}} />
       <Button width={55}  text="Save" onBtnClick={ handleSubmit(onSubmit)} />

 </div>


        
      </div>
    </div>
  );
};

export { LocationModal };
