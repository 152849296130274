import { useSelector } from "react-redux";
import { onAddClass, onGetSchoolClasses } from "../../services/school";
import { useEffect, useState } from "react";
import ClassItem from "../../components/payments/classes/ClassItem";
import Spinner from "../../components/generic/Spinner";
import { onGetAllstaffs } from "../../services/user";
import { useNavigate } from "react-router-dom";
import Button from "../../components/generic/Button";
import { PiPlus } from "react-icons/pi";
import { toast } from "react-toastify";
import AddClassModal from "../../components/payments/classes/AddClassModal";

export default () => {
  const Userschool = useSelector((state) => state.userschool);
  const [schoolClasses, setschoolClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showaddclass, setshowaddclass] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [allstaffs, setallstaffs] = useState([]);
  const navigate = useNavigate();

  
  const getSchoolClasses = async () => {
    let postData = {
      schoolname: Userschool.schoolname,
    };

    setLoading(true);
    var response = await onGetSchoolClasses(postData);
    setLoading(false);
    var emptyArr = [];
    Object.keys(response).map((key) => {
      emptyArr.push(response[key]);
    });
    setschoolClasses(
      emptyArr.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getallstaffs = async () => {
    setLoading(true);
    var response = await onGetAllstaffs();
    setLoading(false);

    var emptyArr = [];
    Object.keys(response).map((key) => {
      if (response[key].schhool == Userschool.schoolname)
        emptyArr.push(response[key]);
    });
    setallstaffs(emptyArr);
    console.log(emptyArr);
  };




  useEffect(() => {
    if (Userschool?.schoolname == undefined) {
      navigate("/dashboard");
    }
  });

  useEffect(() => {
    getSchoolClasses();
    getallstaffs();
  }, [refresh]);

  return (
    <div className="px-4 flex flex-col bg-[#fff] py-10">
      <Spinner loading={loading} />
{showaddclass&&<AddClassModal refresh={getSchoolClasses} show={setshowaddclass}/>}
      <div className="flex justify-between items-center ">

      <p className="font-semibold text-lg my-1 text-primary ">
        Classes ({schoolClasses.length})
      </p>

      <Button
            width={150}
            text={<><PiPlus className="inline"/> Add Class  </>}
            onBtnClick={ ()=>{
 setshowaddclass(true)
            }}
          />
      </div>
     
      <div className=" px-6 overflow-y-scroll overflow-x-hidden h-screen ">
        {schoolClasses.map((item, key) => (
          <ClassItem
            key_={key}
            refresh={getSchoolClasses}
            staffs={allstaffs}
            setLoading={setLoading}
            item={item}
          />
        ))}
      </div>{" "}
    </div>
  );
};
