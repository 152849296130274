import axios from "axios";
import { baseURL } from "../utils/URL";





const onAddFacility= async (formdata)=>{



    try {
        const response = await axios.post(`${baseURL}/addfacility`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }});
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }

}



const onGetAllfacility = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/getfacility`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const onDeleteFacility = async (postData) => {
    try {
        const response = await axios.post(`${baseURL}/deletefacility`, postData);
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};



export{onAddFacility, onGetAllfacility, onDeleteFacility}