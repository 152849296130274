import { useForm } from "react-hook-form";
import InputField from "../components/generic/Inputfield";
import { emailReg, passwordReg } from "../utils/regex";
import { NGcoatofam, coatofarm, hedgelogo, logo } from "../constants/imports";
import Button from "../components/generic/Button";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/generic/Spinner";
import { useEffect, useState } from "react";
import { onLogin } from "../services/auth";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SendVerificationlink } from "../utils/functions";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const authuser = useSelector((state) => state.authuser);

  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();

  var dispather = useDispatch();

  useEffect(() => {
    if (authuser.firstname) {
      navigate("/dashboard");
      return;
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    let formData = {
      email: data.email,
      password: data.password,
    };
    try {
      setLoading(true);
      let res = await onLogin(formData);
      toast(res ? res.message : " An error occured");

      if (res.user) {
        if (res.user.verified) {
          dispather({ type: "set-auth-user", payload: res.user });

          navigate("/dashboard");
          localStorage.setItem(`UserData`, JSON.stringify(res?.user));
        } else {
          toast(
            "Account not verified, we sent an email to verify your account"
          );
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className="bg-primary flex justify-center h-[100vh] items-center">
        <div className="bg-white min-w-[28vw]  rounded-lg p-2 flex justify-center  items-center flex-col ">
          <img className="mb-10 w-[130px]" src={logo} />

          <p className="text-[14px] mb-4 font-medium text-gray-700"> Login to account</p>

          <div className="w-full px-6">
            <InputField
              name="email"
              title="Email Address"
              placeholder="Enter Email Address"
              control={control}
              rules={{
                required: "Email Address is required",
                pattern: {
                  value: emailReg,
                  message: "Invalid Email Address",
                },
              }}
            />

            <InputField
              name="password"
              title="Password"
              placeholder="Enter password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              type={"password"}
            />

            <div className="w-full flex justify-between items-center">
              {" "}
              <p className="text-[13px] text-center text-primary">
                <input type="checkbox" /> Remember me{" "}
              </p>{" "}
              <Link to="/resetpassword">
                <p className=" text-primary text-[13px] cursor-pointer">
                  Forgot password
                </p>
              </Link>
            </div>
            <Button text="Login" onBtnClick={handleSubmit(onSubmit)} />
            <p className="text-primary text-[13px] w-full text-center">
              Don't have an account?{" "}
              <Link className="font-semibold" to="/signup">
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export { Login };
