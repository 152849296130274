import { useForm } from "react-hook-form";

import { addressRegex, textReg } from "../../../utils/regex";
import Button from "../../generic/Button";
import InputField from "../../generic/Inputfield";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";
import { onAddClass, onDeleteClass } from "../../../services/school";
import Spinner from "../../generic/Spinner";

export default ({ item, show, refresh }) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm();
  var Userschool = useSelector((state) => state.userschool);
  const onSubmit = async (data) => {
    console.log(data);

    var postData = { schoolname: Userschool.schoolname, classname: data.name };

    try {
      setLoading(true);
      var response = await onAddClass(postData);
      toast.success(response.message);
      refresh();
      toast.success("Updated");
      setLoading(false);
      show(false);
    } catch (e) {
      toast.error(e);
      console.log(e);
      setLoading(false);
    }
  };

  const DeleteClass = async () => {
    var postData = { schoolname: Userschool.schoolname, classname: item.code };

    try {
      setLoading(true);
      var response = await onDeleteClass(postData);
      show(false)
      refresh();
      toast.success("Deleted");
      setLoading(false);
    } catch (e) {
      toast.error(e);
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <div className="fixed top-0 left-0 z-50 flex justify-center items-center bg-[#000000a7] w-[100vw] h-[100vh]">
      <Spinner loading={loading} />

      <div className="bg-white md:w-[30vw] rounded px-10 py-4">
        <p className="text-primary font-semibold">Delete Class</p>

        <p className="self-center text-xs" >
          Are you sure you want to delete this item?
        </p>
        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              show(false);
            }}
          />
          <Button

            width={55}
            text="Delete"
            onBtnClick={async () => {
              await DeleteClass();
            }}
          />
        </div>
      </div>
    </div>
  );
};
