import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SideBar } from "../components/generic/Sidebar";
import { Navbar } from "../components/ui/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { onGetUser } from "../services/user";
import { useMediaQuery } from "react-responsive";
import Spinner from "../components/generic/Spinner";
import { onGetSchool } from "../services/school";
import { toast } from "react-toastify";

const Dashboard = () => {
  const authuser = useSelector((state) => state.authuser);
  var location = useLocation();
  const dispatcher = useDispatch();
  var navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  });
  const getSchoolinfo = async () => {
    const formdata = { name: authuser.school };

    try {
      setLoading(true);
      //get school info
      let res = await onGetSchool(formdata);
      dispatcher({ type: "set-user-school", payload: res });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    //   if (isDesktopOrLaptop) {
    // console.log("isdesktop");
    if (!authuser.firstname) {
      navigate("/login");
      return;
    } else if (authuser.school == "nill") {
      navigate("/dashboard/registerschool");
    } else if (!(authuser.school == "nill")) {
      navigate(location.pathname);
      // console.log(location.pathname)
    }
    //   }

    // else {
    //   console.log("not desktop");
    //   navigate("/mobile");
    // }
  }, [authuser.school]);

  useEffect(() => {
    getSchoolinfo();
  }, []);

  return (
    <div className={`w-full h-[85vh] md:flex`}>
      <Spinner loading={loading} />
      {isDesktopOrLaptop && (
        <div className="w-[18vw] ">
          <SideBar />
        </div>
      )}

      <div
        className={`${
          isDesktopOrLaptop ? "w-[82vw]" : "100vw"
        } h-[100vh] bg-[#F3F2F7] `}
      >
        <Navbar />
        <h1 className="font-bold mx-4 my-4 text-lg p-3">
          {useSelector((state) => state.ui.pagetitle)}
        </h1>
        <Outlet className=" bg-primary  w-full h-[100vh]" />
      </div>
    </div>
  );
};

export { Dashboard };
