import { FaCheck, FaCheckCircle } from "react-icons/fa"
import { logo, logolight } from "../constants/imports"
import Button from "../components/generic/Button"
import { useNavigate, useParams } from "react-router-dom"
import { onVerifyuser } from "../services/user"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

const Verify =()=>{
var navigate=useNavigate()
const [message, setmessge]=useState()
var {userId} =useParams()

const verify= async()=>{

var postdata={
    email:userId
}
    var res =await onVerifyuser(postdata)
    toast.success(res.message)
    setmessge(res.message)
}

useEffect(()=>{


    verify()
},[])
    return(<div className="bg-primary flex justify-center items-center h-[100vh] w-full ">


        <div className="bg-white rounded-lg  w-[80vw]  h-[50vh]   flex justify-center flex-col  items-center" >
            
            <img  className="flex justify-center mb-4 w-[130px] items-center" src={logo} />
            <FaCheckCircle className="text-primary mb-4"    size={30} />
            <p>{userId}</p>
            <h1 className="text-lg font-light mb-4"> 
            {message}</h1>
            
            <Button  width={'auto'} text="Login" onBtnClick={()=>{

navigate('/login')


            }} />
            </div>
           


    </div>)
}


export {Verify}