import { Menu,Transition  } from "@headlessui/react"
import React from "react"
import { Fragment, useEffect, useRef, useState } from 'react'
import { FaAngleDown, FaNotEqual } from "react-icons/fa"
import { useSelector } from "react-redux"
import { categories, roles } from "../../constants/data"
import { TbCategoryFilled } from "react-icons/tb"

const Categorypicker =(props )=>{

 
var {selected, setselected} =props.hook



    return (<>
      <Menu as="div" className="relative">
            <div><p className="text-xs mb-2">Choose School Category</p>
              <Menu.Button className="  px-4 py-2  flex text-sm bg-gray-50 w-full  rounded    ">
                <div className="flex flex-items w-full items-center gap-4">
                  <div 
                    className=" flex justify-between w-full  items-center p-1" ><p className="text-xs font-light"> <TbCategoryFilled className='text-black inline' size={20}/> {selected? selected.map((i)=>`${i} `):"Select Your role" } </p>   <FaAngleDown color="#777777"/>
                  </div>

                  <div className="flex items-center">
                 
                  </div>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-left z-10 absolute left-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          

              {categories.map ((item)=>(

          
<Menu.Item>
  {({ active }) => (
    <p onClick={()=>{
  

  if(selected.includes(item.name)){

    const indexToRemove = selected.indexOf(`${item.name}`);

    const newArray = [...selected.slice(0, indexToRemove), ...selected.slice(indexToRemove + 1)];
 setselected(newArray)
  }


  else{

    var list =[...selected,item.name]

  

    setselected([...new Set(list)])    
 }
  }
}
      className= ' text-sm font-light w-full text-black p-3 m-2 cursor-pointer truncate hover:bg-customGray '>
  <input type="checkbox" checked={selected.includes( item.name)} name={item.id}/>{item.name} 
   
    </p>
  )}
</Menu.Item>
))}


              </Menu.Items>
            </Transition>
          </Menu>


</>)}

export {Categorypicker}