import { useDispatch, useSelector } from "react-redux";
import { CertificateItem } from "../../components/Certificates/CertificateItem";
import { useEffect, useState } from "react";
import { onGetAllcertificates } from "../../services/certificates";
import Spinner from "../../components/generic/Spinner";
import AddFacilitymodal from "../../components/facilities/Addfacilitymodal";
import UpdateCert from "../../components/Certificates/UpdateCert";
import ViewCert from "../../components/Certificates/ViewCert";
import { ConfirmDelete } from "../../components/Certificates/DeleteConfirm";
import DoNotApply from "../../components/generic/DoNotApply";

const headeritems = ["Name", "Status", "Actions"];

const Certificates = () => {
  const dispatcher = useDispatch();
  var [certificates, setcerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedcert, setselectedcert] = useState();
  var authuser = useSelector((state) => state.authuser);
  dispatcher({ type: "set-page-title", payload: "Certificates" });
  var [showaddmodal, setshowaddmodal] = useState(false);
  var [showview, setshowview] = useState(false);
  var [showdeletemodal, setshowdeletemodal] = useState(false);
  var userschool = useSelector((state) => state.userschool);

  const init = async () => {
    const postdata = { school: authuser.school };
    console.log(postdata, "certificates");
    setLoading(true);
    var res = await onGetAllcertificates(postdata);
    setLoading(false);
    if (res.certicates) {
      setcerts(res.certicates);
      console.log(res.certicates);
    }

    return res.certicates;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="h-full bg-customGray p-10 ">
      <Spinner loading={loading} />
      {showaddmodal && (
        <UpdateCert
          refresh={init}
          selectedcert={selectedcert}
          showaddmodal={setshowaddmodal}
        />
      )}
      {showdeletemodal && (
        <ConfirmDelete
          loading={setLoading}
          refresh={init}
          selectedcert={selectedcert}
          showaddmodal={setshowdeletemodal}
        />
      )}
      {showview && (
        <ViewCert
          selectedcert={selectedcert}
          certificates={certificates}
          showaddmodal={setshowview}
        />
      )}

      <div className="bg-white p-3">
        {/* Table Header */}

        <div className="bg-customGray my-2 flex w-full px-2 py-3 justify-between">
          {headeritems.map((item) => (
            <p className="uppercase text-xs  text-black">{item}</p>
          ))}
        </div>

   { userschool.schooltype =="Private School"?
        <div>
        {certificates.map((item, key) => (
          <CertificateItem
            setshowdel={setshowdeletemodal}
            setshowview={setshowview}
            setshowaddmodal={setshowaddmodal}
            key_={key}
            setselectedcert={setselectedcert}
            item={item}
          />
        ))}
        </div>
        :<DoNotApply/>
    
    }

      </div>
    </div>
  );
};

export { Certificates };
